
export enum SortDirection {
    ASC,
    DESC,
    NULL
}

export default {
    methods: {
        orderBy(array: Array<Object>, column: string, defaultColumn: string): Array<Object> {
            this.setSortDirection(column)
            let sortDirection = this.sortDirection
            this.currentColumn = column

            if (this.sortDirection === SortDirection.NULL) {
                column = defaultColumn
                sortDirection = SortDirection.ASC
            }

            return array.sort((a, b) => {
                if (!this.isNull(a[column]) && sortDirection === SortDirection.ASC) {
                    return a[column].trim().localeCompare(b[column])
                }

                if (!this.isNull(b[column]) && sortDirection === SortDirection.DESC) {
                    return b[column].trim().localeCompare(a[column])
                }

                return 0
            })

        },

        setSortDirection(column) {
            if (column !== this.currentColumn) {
                this.sortDirection = SortDirection.ASC

                return
            }

            switch (this.sortDirection) {
                case SortDirection.ASC:
                    this.sortDirection = SortDirection.DESC
                    break;
            
                case SortDirection.DESC:
                    this.sortDirection = SortDirection.NULL
                    
                    break;
            
                case SortDirection.NULL:
                    this.sortDirection = SortDirection.ASC
                    
                    break;
            
                default:
                    this.sortDirection = SortDirection.ASC
                    break;
            }
        },

        isSortIconVisible(column) {
            return this.currentColumn === column && this.sortDirection !== SortDirection.NULL
        }
    }
}