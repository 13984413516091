<template>
    <div class="list internal">
        <div :class="inputListClasses">
            <div v-for="(model, index) in models">
                <div :class="{'hidden': model.is_marked_for_destruction}" class="flex">
                    <input :name="`${modelName}s[${index}][id]`" type="hidden" :value="model.id" v-if="model.id !== null">
                    <input :name="`${modelName}s[${index}][is_marked_for_destruction]`" type="hidden" v-model="model.is_marked_for_destruction" v-if="model.is_marked_for_destruction">

                    <!-- <slot v-bind:[modelName]="model" :index="index"></slot> -->

                    <div @click="removeModel(index, model)" class="ml-2 mb-5 flex items-center">
                        <img class="p-1 h-8 cursor-pointer" src="/img/icon-delete.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="simple-action" @click="addModel" v-if="isAddModelButtonVisible">+ <span v-html="displayName"></span> hinzufügen</div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

import { AbstractManagedModel } from "../../typings/types";

type AbstractSelectorManagerData = {
    models: Array<AbstractManagedModel>,
}

export default defineComponent({
    props: {
        displayName: {
            type: String,
        },
        inputListClasses: {
            type: String
        },
        modelName: {
            type: String,
            required: true,
        },
        initialModels: {
            type: Array as () => Array<AbstractManagedModel>,
            required: true,
        },
        maxModelCount: {
            type: Number,
            default: Infinity,
        },
    },

    data(): AbstractSelectorManagerData {
        return {
            models: this.initialModels
        }
    },

    computed: {
        // isAddModelButtonVisible: function() {
        //     return this.usesDefaultAddModelButton && this.models.length < this.maxModelCount
        // }
    },

    mounted() {
        // if (this.usesDefaultAddModelButton && this.models.length < this.maxModelCount && this.maxModelCount > 1) {
        //     this.addModel();
        // }
    },

    methods: {
        addModel() {
            // const newModel: AbstractManagedModel = {
            //     id: null,
            //     name: ''
            // }

            // this.models.push(newModel)
        },

        removeModel(index: number, model: AbstractManagedModel) {
            if (model.id !== null) {
                model.is_marked_for_destruction = true

                return;
            }

            this.models.splice(index, 1)
        }
    }
})

</script>
