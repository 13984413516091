<template>  
    <div 
        class="flex items-center border border-gray-500 px-2 h-[30px] bg-gray-50 shadow-md hover:bg-white hover:shadow-lg cursor-pointer" 
        :class="$attrs.class"
        @click="openEmailModal">

        <!-- <font-awesome-icon class="text-xl" :icon="'envelope'"></font-awesome-icon> -->
        <span class="text-xl">📩?</span>
    </div>

    <abstract-modal :is-open="isModalOpen" :title="modalTitle" v-on:closeModal="closeEmailModal">
        <template v-slot:modalBody>
            <blockquote v-if="hasError" class="bg-red-100 text-red-700 px-3 py-2 my-5 border border-red-200">Es ist ein Fehler beim Versenden der E-Mail aufgetreten. Senden Sie bitten einen Screenshot, Leadnummer und eine Beschreibung der letzten Schritte an den Administrator.</blockquote>
            <blockquote v-if="hasSuccess" class="bg-green-100 text-green-700 px-3 py-2 my-5 border border-green-200">E-Mail wurde erfolgreich versendet</blockquote>


          <!-- if mailTo is set, show a input field with the mailTo address -->
          <div v-if="forVendor" class="mb-5">
            <label class="block mb-1">An</label>
            <!-- prevent text reset on editor update -->
            <input type="text" class="form-control" v-model="email" @input="email = $event.target.value" for="mailTo">
          </div>
          <quill-editor
              v-model="this.emailTemplate"
              :content="this.emailTemplate"
              :content-type="'html'"
              class="min-h-[300px]"
              @text-change="updateMessage"
              theme="snow"></quill-editor>



          <!-- send and cancel buttons -->
        <div class="flex justify-end mt-5">
            <!-- if forVendor is set use sendToVendor, else sendEmail -->
            <button class="btn btn-primary" @click="sendToVendor" :disabled="isLoading" v-if="forVendor">Senden</button>
            <button class="btn btn-primary" @click="sendEmail" :disabled="isLoading" v-else>Senden</button>

            <button class="btn btn-secondary ml-2" @click="closeEmailModal" :disabled="isLoading">Abbrechen</button>
        </div>

        </template>
    </abstract-modal>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractModal from "@/components/AbstractModal.vue";
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";
import {QuillEditor} from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import {Vendor} from "../../typings/types";

type SendEmailData = {
    isModalOpen: boolean,
    isLoading: boolean,
    hasError: boolean,
    hasSuccess: boolean,
    message: string,
}

export default defineComponent({
    props: {
        emailTemplate: {
          type: String,
            default: `
<p>Lieber Interessent,</p>
<p>erst einmal vielen Dank für Ihre Anfrage.</p>
<p>Wir haben die richtige Software für Sie in unserer Datenbank gefunden, eine kostenfreie und zwei kostenpflichtige. Eines der Programme läuft ein Jahr kostenfrei. Für die beiden kostenpflichtigen würden wir Ihnen die Angebote zusenden. Bitte nutzen Sie diesen kostenfreien Link um das Angebot abzurufen: <a href='#additionalDataLink'>Leadempfehlung</a></p>
<p>Nach Ergänzung der Daten geht Ihnen das Angebot per E-Mail binnen 48 h zu.</p>
<p> Falls Gewünscht können Sie auch hier einen Termin buchen: <a href="https://online.systemhaus.com">https://online.systemhaus.com</a> </p>
<p><strong>Hinweis:</strong> Dieser Service ist kostenfrei und wir finanzieren uns und unsere Datenbank aus Werbeanzeigen die auf unserer Seite mit großer Leserreichweite geschalten werden.</p>
<p>
    --<br>
    Herzliche Grüße<br>
    Ihr Team von Systemhaus.com
</p>

<p>
    Systemhaus.com Website<br>
    Gartenstraße 69<br>
    63225 Langen
</p>
<div style="color: rgba(108, 117, 125, 1); font-size: 10px; margin-top: 20px; padding-top: 20px; border-top: 1px solid rgba(233, 236, 239, 1);">
    <p>Telefon: <a href="tel:036130251390">0361 30 25 1390</a></p>
    <p>Impressum: <a href="https://systemhaus.com/impressum">https://systemhaus.com/impressum</a></p>
    <p>HINWEIS: Der Inhalt der empfangenen E-Mail wird vertraulich behandelt und nur vom Empfänger/Vertreter verwendet. Wir weisen darauf hin, dass E-Mail-Inhalte aus rechtlichen und Sicherheitsgründen nicht rechtsverbindlich sind. Auf Wunsch erhalten Sie eine rechtsverbindliche schriftliche Bestätigung. Nur mit unserer schriftlichen Zustimmung darf der Inhalt der E-Mail veröffentlicht, kopiert oder weitergeleitet werden. Erklärungen oder Informationen gegenüber Empfängern unterliegen deren Geschäftsrecht, Sie müssen den geltenden Allgemeinen Geschäftsbedingungen und individuellen Vereinbarungen entsprechen. Sollten Sie nicht der beabsichtigte Empfänger unserer E-Mail sein, werden wir Sie bitten, sich umgehend mit dem Absender dieser E-Mail in Verbindung zu setzen und die erhaltene Sendung aus Ihrem System zu löschen.</p>
    <p>Pflichtinformationen gemäß Artikel 13 DSGVO: Im Falle des Erstkontakts sind wir gemäß Art. 12, 13 DSGVO verpflichtet, Ihnen folgende datenschutzrechtliche Pflichtinformationen zur Verfügung zu stellen: Wenn Sie uns per E-Mail kontaktieren, verarbeiten wir Ihre personenbezogenen Daten nur, soweit an der Verarbeitung ein berechtigtes Interesse besteht (Art. 6 Abs. 1 lit. f DSGVO), Sie in die Datenverarbeitung eingewilligt haben (Art. 6 Abs. 1 lit. a DSGVO), die Verarbeitung für die Anbahnung, Begründung, inhaltliche Ausgestaltung oder Änderung eines Rechtsverhältnisses zwischen Ihnen und uns erforderlich sind (Art. 6 Abs. 1 lit. b DSGVO) oder eine sonstige Rechtsnorm die Verarbeitung gestattet. Ihre personenbezogenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung wid

`,
        },
        leadId: {
            type: Number,
            default: null,
        },
        // optional lead object
        lead: {
            type: Object,
            default: () => {}, // hehe :3
        },
        modalTitle: {
            type: String,
            default: "Email an Interessenten senden",
        },
        mailTo: {
            type: String,
            default: null,
        },
      forVendor: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      QuillEditor,
        'abstract-modal': AbstractModal,
        'editor': Editor,
    },

    data() {
        return {
            isModalOpen: false,
            isLoading: false,
            hasError: false,
            hasSuccess: false,
            message: this.emailTemplate,
            email: this.mailTo,
        }
    },

    methods: {
        openEmailModal() {
            this.isModalOpen = true
        },

        closeEmailModal() {
            this.isLoading = false
            this.hasError = false
            this.hasSuccess = false

            this.isModalOpen = false
        },

        sendEmail() {
            const url = `/leads/${this.leadId}/send-mail`;

            this.isLoading = true
            this.hasError = false
            this.hasSuccess = false

            axios.put(url, {
                    message: this.message
                })
                .then(() => {
                    this.hasSuccess = true
                }).catch(() => {
                    this.hasError = true
                }).finally(() => {
                    this.isLoading = false
                })
        },

      sendToVendor(event) {
        event.preventDefault();
        const email = document.querySelector('input[for="mailTo"]').value;

        if (!email) {
          alert('Bitte geben Sie eine E-Mail Adresse ein')
          return
        }

        // prepare data for log
        const lead_data = JSON.parse(JSON.stringify(this.lead))
        /*
        * needed:
        * lead {
        *   id, full_name, company_name, email, phone, street, zip, city, message
        * },
        * wizard_questions {
        *   id, question, answer where answer is inside lead_answer.answer
        * }
        * */

        // extract user data and sectors[0].wizard_questions
        const user_data = lead_data.user
        const wizard_questions = lead_data.sectors[0].wizard_questions
        const payload = {
          user_data,
          wizard_questions,
          msg: lead_data.message,
          send_to: email,
          main_message: this.message
        }

        const url = `/newven/snae`

        fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify(payload)
        }).then((response) => {
          if (response.status === 200) {
            this.hasSuccess = true
          } else {
            this.hasError = true
          }
        }).catch(() => {
          this.hasError = true
        }).finally(() => {
          this.isLoading = false
        })

      },

        updateMessage(event) {
          console.log(event)

        },
        applyFormat(command, value = null) {
            // Execute formatting commands
            document.execCommand(command, false, value);
            this.updateMessage(); // Update the message after formatting
        },
        updateEditorContent(event) {
            // make sure email is not reset
            let email = document.querySelector('input[for="mailTo"]').value;
            email = this.email
            // update word count
            console.log(event)
        },

    }
})

</script>

<style scoped>

.editor-container {
    max-height: 500px;
    overflow-y: auto;
}

/* style for editor content */

#editor p {
    margin: 0;
}

#editor a {
    color: #3182ce;
    text-decoration: underline;
}

/* Add any custom styles for your component here */
.formatters {
    margin-bottom: 10px;
}

.editor-container {
    display: flex;
}

</style>