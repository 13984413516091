<template>
    <div class="cursor-pointer flex select-none" @click="isExpanded = !isExpanded">
        <div class="font-semibold self-center flex">
            <font-awesome-icon class="mr-4 mt-1.5 text-4xl transform transition-transform" :class="{'rotate-90': isExpanded}" :icon="['fa', 'caret-right']"/>
        </div>

        <h2><span v-html="name"></span> Eigenschaften (<span v-html="initialFeatures.length"></span>)</h2>
    </div>
    
    <abstract-manager  
        :class="{'opacity-0 h-0 overflow-hidden': !isExpanded}"
        modelName="feature"
        displayName="Eigenschaft"
        :deleteButtonClasses="'mt-7'"
        :initialModels="initialFeatures"
        :errorIndexes="errorIndexes">
        
        <template v-slot:default="slotProps: Feature">
            <div class="lmt-form-group w-1/3 pr-3">
                <label :for="`features[${slotProps.index}][name]`">Name <span class="text-red-500">*</span></label>
                <input :id="`features[${slotProps.index}][name]`" :name="`features[${slotProps.index}][name]`" type="text" v-model="slotProps.feature.name">
                <span class="text-red-500" v-if="hasError('name', slotProps.index)" v-html="getError('name', slotProps.index)"></span>
            </div>

            <div class="lmt-form-group flex-grow">
                <label :for="`features[${slotProps.index}][short_description]`">Kurzbeschreibung</label>
                <input :id="`features[${slotProps.index}][short_description]`" :name="`features[${slotProps.index}][short_description]`" type="text" v-model="slotProps.feature.short_description">
                <span class="text-red-500" v-if="hasError('short_description', slotProps.index)" v-html="getError('short_description', slotProps.index)"></span>
            </div>
        </template>

        <template v-slot:body="slotProps">
            <div class="flex justify-between mb-2">
                <div class="lmt-form-group flex-grow">
                    <label :for="`features[${slotProps.index}][description_part_1]`">Beschreibung Teil 1</label>
                    <input :id="`features[${slotProps.index}][description_part_1]`" :name="`features[${slotProps.index}][description_part_1]`" type="text" v-model="slotProps.feature.description_part_1">
                    <span class="text-red-500" v-if="hasError('description_part_1', slotProps.index)" v-html="getError('description_part_1', slotProps.index)"></span>
                </div>

                <div class="lmt-form-group px-2">
                    <label :for="`features[${slotProps.index}][description_variable]`">Platzhalter</label>
                    <select :name="`features[${slotProps.index}][description_variable]`" v-model="slotProps.feature.description_variable">
                        <option :value="null" hidden>-</option>
                        <option value="Name Software" :selected="slotProps.feature.description_variable === 'Name Software'">Name Software</option>
                        <option value="Name Anbieter" :selected="slotProps.feature.description_variable === 'Name Anbieter'">Name Anbieter</option>
                    </select>
                </div>

                <div class="lmt-form-group flex-grow">
                    <label :for="`features[${slotProps.index}][description_part_2]`">Beschreibung Teil 2</label>
                    <input :id="`features[${slotProps.index}][description_part_2]`" :name="`features[${slotProps.index}][description_part_2]`" type="text" v-model="slotProps.feature.description_part_2">
                    <span class="text-red-500" v-if="hasError('description_part_2', slotProps.index)" v-html="getError('description_part_2', slotProps.index)"></span>
                </div>
            </div>

            <div class="flex justify-between">
                <div class="lmt-form-group flex-grow">
                    <label :for="`features[${slotProps.index}][not_available_description]`">Beschreibung wenn nicht vorhanden</label>
                    <input :id="`features[${slotProps.index}][not_available_description]`" :name="`features[${slotProps.index}][not_available_description]`" type="text" v-model="slotProps.feature.not_available_description">
                    <span class="text-red-500" v-if="hasError('not_available_description', slotProps.index)" v-html="getError('not_available_description', slotProps.index)"></span>
                </div>

                <div class="lmt-form-group px-4">
                    <label class="pr-4" :for="`features[${slotProps.index}][has_answer]`">Hat eigene Antwort</label>
                    <select :name="`features[${slotProps.index}][has_answer]`" v-model="slotProps.feature.has_answer">
                        <option value="" hidden>-</option>
                        <option value="0" :selected="!slotProps.feature.hasOwnProperty('has_answer') || slotProps.feature.has_answer">Nein</option>
                        <option value="1" :selected="slotProps.feature.has_answer">Ja</option>
                    </select>
                </div>

                <div class="lmt-form-group">
                    <label :for="`features[${slotProps.index}][position_in_price_calculator]`">Position im Preiskalkulator</label>
                    <input :id="`features[${slotProps.index}][position_in_price_calculator]`" :name="`features[${slotProps.index}][position_in_price_calculator]`" type="number" v-model="slotProps.feature.position_in_price_calculator">
                    <span class="text-red-500" v-if="hasError('position_in_price_calculator', slotProps.index)" v-html="getError('position_in_price_calculator', slotProps.index)"></span>
                </div>
            </div>

            <div>
                <div v-if="parentType === 'vendor'" class="flex justify-between">
                    <div class="lmt-form-group flex-grow pr-2">
                        <label :for="`features[${slotProps.index}][value]`">Wert</label>
                        <input :id="`features[${slotProps.index}][value]`" :name="`features[${slotProps.index}][value]`" type="text" v-model="slotProps.feature.value">
                    </div>
                    <div class="lmt-form-group flex-grow pl-2">
                        <label :for="`features[${slotProps.index}][comment]`">Bemerkung</label>
                        <input :id="`features[${slotProps.index}][comment]`" :name="`features[${slotProps.index}][comment]`" type="text" v-model="slotProps.feature.comment">
                    </div>
                </div>

                <div v-if="parentType === 'vendor'" class="flex justify-between pb-3">
                    <div>
                        <label class="font-bold block">Versteckt</label>
                        <div class="lmt-form-group-radio inline-block mr-5">
                            <label :for="`features[${slotProps.index}][is_hidden][yes]`">
                                <input :id="`features[${slotProps.index}][is_hidden][yes]`" :name="`features[${slotProps.index}][is_hidden]`" type="radio" value="1" v-model="slotProps.feature.is_hidden">
                                <span>Ja</span>
                            </label>
                        </div>
                        <div class="lmt-form-group-radio inline-block">
                            <label :for="`features[${slotProps.index}][is_hidden][no]`">
                                <input :id="`features[${slotProps.index}][is_hidden][no]`" :name="`features[${slotProps.index}][is_hidden]`" type="radio" value="0" selected="true" v-model="slotProps.feature.is_hidden">
                                <span>Nein</span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <label class="font-bold block text-center">Vorhanden</label>
                        <div class="lmt-form-group-radio inline-block mr-5">
                            <label :for="`features[${slotProps.index}][is_available][yes]`">
                                <input :id="`features[${slotProps.index}][is_available][yes]`" :name="`features[${slotProps.index}][is_available]`" type="radio" value="1" v-model="slotProps.feature.is_available">
                                <span>Ja</span>
                            </label>
                        </div>
                        <div class="lmt-form-group-radio inline-block mr-2">
                            <label :for="`features[${slotProps.index}][is_available][no]`">
                                <input :id="`features[${slotProps.index}][is_available][no]`" :name="`features[${slotProps.index}][is_available]`" type="radio" value="0" selected="true" v-model="slotProps.feature.is_available">
                                <span>Nein</span>
                            </label>
                        </div>
                        <!-- <div class="lmt-form-group-radio inline-block">
                            <label :for="`features[${slotProps.index}][is_available][partially]`">
                                <input :id="`features[${slotProps.index}][is_available][partially]`" :name="`features[${slotProps.index}][is_available]`" type="radio" value="0" selected="true">
                                <span>Teilweise</span>
                            </label>
                        </div> -->
                    </div>
                    <div>
                        <label class="font-bold block text-right">Gegen Aufpreis</label>
                        <div class="lmt-form-group-radio inline-block mr-5">
                            <label :for="`features[${slotProps.index}][is_extra_charge_required][yes]`">
                                <input :id="`features[${slotProps.index}][is_extra_charge_required][yes]`" :name="`features[${slotProps.index}][is_extra_charge_required]`" type="radio" value="1" v-model="slotProps.feature.is_extra_charge_required">
                                <span>Ja</span>
                            </label>
                        </div>
                        <div class="lmt-form-group-radio inline-block">
                            <label :for="`features[${slotProps.index}][is_extra_charge_required][no]`">
                                <input :id="`features[${slotProps.index}][is_extra_charge_required][no]`" :name="`features[${slotProps.index}][is_extra_charge_required]`" type="radio" value="0" selected="true" v-model="slotProps.feature.is_extra_charge_required">
                                <span>Nein</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </abstract-manager>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractManager from "./AbstractManager.vue";
import { AbstractManagedModel, Sector } from "../../typings/types";

type Feature = AbstractManagedModel


export default defineComponent({
    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        initialFeatures: {
            type: Array as () => Array<Feature>,
            default: new Array<Feature>(),
        },
        name: {
            type: String,
            required: true,
        },
        errors: {
            type: Object,
            default: {},
        },
        parentType: {
            type: String,
            options: ['vendor', 'sector']
        }
    },

    data() {
        return {
            isExpanded: Object.values(this.errors).length > 0
        }
    },

    computed: {
        errorIndexes: function() {
            const errorKeys = Object.keys(this.errors)
            const regex = /\.(\d+)\./g
            let indexes: Array<number> = []

            errorKeys.forEach((errorKey: string) => {
                const matches = regex.exec(errorKey)

                if (matches !== null && typeof matches[1] !== 'undefined') {
                    indexes.push(Number(matches[1]));
                }
            })

            return indexes
        }
    },

    methods: {
        hasError(key: string, index: number) {
            const errorKeys = Object.keys(this.errors)

            if (errorKeys.length < 1) {
                return false
            }

            return -1 < (errorKeys.findIndex(errorKey => {
                return errorKey.indexOf(`.${index}.${key}`) > -1
            }))
        },

        getError(key: string, index: number) {
            const errorKeys = Object.keys(this.errors)

            const errorIndex = errorKeys.findIndex(errorKey => {
                return errorKey.indexOf(`.${index}.${key}`) > -1
            })

            return Object.values(this.errors)[errorIndex][0]
        }
    }
})

</script>
