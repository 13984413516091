import { Module } from 'vuex'
import { Sector, Vendor } from '../typings/types'
import { RootStoreInterface } from '.'

export interface LeadInterface {
    sectors: Array<Sector>|null,
    isDataTransferAccepted: boolean,
}

export const Lead: Module<LeadInterface, RootStoreInterface> = {
    namespaced: true,
    state: {
        sectors: null,
        isDataTransferAccepted: null,
    },
    getters: {
        getSectors(state) {
            return state.sectors
        },
        getIsDataTransferAccepted(state) {
            return state.isDataTransferAccepted
        }
    },
    mutations: {
        setSectors(state, sectors: Array<Sector>|null) {
            sectors?.forEach((sector: Sector) => {
                sector.vendors = sector.vendors.sort((vendorA: Vendor, vendorB: Vendor) => Number(vendorB.contracts_exists) - Number(vendorA.contracts_exists))
                sector.vendors = sector.vendors.sort((vendorA: Vendor, vendorB: Vendor) => Number(vendorA.current_contract === null) - Number(vendorB.current_contract === null))
                sector.vendors = sector.vendors.sort((vendorA: Vendor, vendorB: Vendor) => Number(vendorB.users_exists) - Number(vendorA.users_exists))
            })

            state.sectors = sectors
        },
        setIsDataTransferAccepted(state, isDataTransferAccepted: boolean) {
            state.isDataTransferAccepted = isDataTransferAccepted
        }
    }
}

export const LeadMap = {
    getters: {
        getSectors: 'Lead/getSectors',
        getIsDataTransferAccepted: 'Lead/getIsDataTransferAccepted'
    },
    mutations: {
        setSectors: 'Lead/setSectors',
        setIsDataTransferAccepted: 'Lead/setIsDataTransferAccepted'
    }
}
