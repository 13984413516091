<template>
    <div :class="$attrs.class">
        <h2 v-html="title"></h2>
        <abstract-manager
            modelName="sector"
            displayName="Branche"
            :initialModels="selectedSectors"
            :selectableModels="selectableSectors"
            :usesDefaultAddModelButton="false"
            :deleteButtonClasses="'mt-1'"
            :newModelId="newSectorId"
            :inputListClasses="!isNull(oldFeatures) ? '' : 'grid grid-cols-3 gap-4'"
            ref="abstractManagerRef">

            <template v-slot:default="slotProps: Sector">
                <div class="flex flex-grow flex-wrap pt-1">
                    <h3 :class="{'w-1/2 lg:w-1/3 2xl:w-1/4': !isNull(oldFeatures)}" v-html="slotProps.sector.name"></h3>
                    <input :name="`sectors[${slotProps.sector.id}][name]`" type="hidden" :value="slotProps.sector.name">

                    <manage-sector-features v-if="!isNull(oldFeatures)" :initialFeatures="slotProps.sector.features"></manage-sector-features>
                </div>
            </template>

            <template v-slot:selector>
                <select class="mt-2 w-full" v-model="newSectorId" @change="triggerSelection">
                    <option :value="null" v-html="'Wähle eine Branche aus'" hidden></option>
                    <option v-for="sector in selectableSectors" :value="sector.id" v-html="sector.name"></option>
                </select>
            </template>

        </abstract-manager>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractManager from "./AbstractManager.vue";
import ManageSectorFeatures from "./ManageSectorFeatures.vue";
import { Sector, Feature } from "../../typings/types";
import mixins from "../../mixins";

type ManageSectorData = {
    newSectorId: number|null,
    selectedSectors: Array<Sector>
}

export default defineComponent({
    mixins: [mixins],

    components: {
        'abstract-manager': AbstractManager,
        'manage-sector-features': ManageSectorFeatures,
    },

    props: {
        sectors: {
            type: Array as () => Array<Sector>,
            required: true,
        },
        initialSelectedSectors: {
            type: Array as () => Array<Sector>,
            default: new Array<Sector>()
        },
        oldFeatures: {
            type: Array as () => Array<Feature>,
            default: null,
        },
        title: {
            type: String,
            default: 'Verwandte Branchen',
            required: false,
        }
    },
    
    data(): ManageSectorData {
        return {
            newSectorId: null,
            selectedSectors: this.initialSelectedSectors,
        }
    },

    computed: {
        selectableSectors: function() {
            return this.sectors.filter(sector => {
                const hasBeenSelected = this.isUndefined(this.selectedSectors.find(selectedSector => {
                    return selectedSector.id === sector.id && !selectedSector.is_marked_for_destruction
                }))

                return hasBeenSelected;
            });
        }
    },

    beforeMount() {
        if (!this.isNull(this.oldFeatures) && this.oldFeatures.length > 0) {
            this.selectedSectors = this.initialSelectedSectors.map(sector => {
                sector.features = this.oldFeatures.filter(feature => {
                    return feature.pivot!.sector_id === sector.id
                })

                return sector
            })
        }
    },

    methods: {
        triggerSelection() {
            (this.$refs.abstractManagerRef as typeof AbstractManager).addModel(this.newSectorId);

            this.newSectorId = null;
        }
    }
})

</script>
