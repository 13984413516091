<template>
    <div :class="$attrs.class">
        <h2>Anbieter</h2>
        <abstract-manager
            modelName="vendor"
            displayName="Anbieter"
            :initialModels="selectedVendors"
            :selectableModels="selectableVendors"
            :usesDefaultAddModelButton="false"
            :newModelId="newVendorId"
            :deleteButtonClasses="'mt-1'"
            ref="abstractManagerRef">

            <template v-slot:default="slotProps: Vendor">
                <div class="flex flex-grow py-2">
                    <span v-html="`<strong>${slotProps.vendor.name}</strong> (${slotProps.vendor.vendor_name}) - ${slotProps.vendor.sectors[0]?.name}`"></span>
                    <input :name="`vendors[${slotProps.vendor.id}][name]`" type="hidden" :value="slotProps.vendor.name">
                    <input :name="`vendors[${slotProps.vendor.id}][vendor_name]`" type="hidden" :value="slotProps.vendor.vendor_name">
                    <input :name="`vendors[${slotProps.vendor.id}][sectors][0][name]`" type="hidden" :value="slotProps.vendor.sectors[0]?.name">
                </div>
            </template>

            <template v-slot:selector>
                <select class="w-full" v-model="newVendorId" @change="triggerSelection">
                    <option :value="null" v-html="'Wähle einen Anbieter aus'" hidden></option>
                    <option v-for="vendor in selectableVendors" :value="vendor.id" v-html="`${vendor.name} (${vendor.sectors[0]?.name})`"></option>
                </select>
            </template>
        </abstract-manager>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue"
import AbstractManager from "./AbstractManager.vue"
import { Vendor } from "../../typings/types"
import mixins from "../../mixins"

export default defineComponent({
    mixins: [mixins],

    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        vendors: {
            type: Array as () => Array<Vendor>,
            default: new Array<Vendor>(),
            required: true
        },
        initialSelectedVendors: {
            type: Array as () => Array<Vendor>,
            default: new Array<Vendor>(),
            required: true
        }
    },
    
    data() {
        return {
            newVendorId: null,
            selectedVendors: this.initialSelectedVendors
        }
    },

    computed: {
        selectableVendors: function() {
            return this.vendors.filter(vendor => {
                const hasBeenSelected = this.isUndefined(this.selectedVendors.find(selectedVendor => {
                    return selectedVendor.id === vendor.id && !selectedVendor.is_marked_for_destruction
                }))

                return hasBeenSelected
            });
        }
    },

    methods: {
        triggerSelection() {
            (this.$refs.abstractManagerRef as typeof AbstractManager).addModel(this.newVendorId)

            this.newVendorId = null
        }
    }
})

</script>
