<template>
    <button v-if="!isLoading && !isBilled" class="px-3 py-2 bg-green-600 hover:bg-green-500 text-white" @click="setToBilled">Abrechnung abschließen</button>
    <div 
        v-if="hasError"
        class="text-red-500 mt-2 bg-red-100 border border-red-200 p-1">
        <font-awesome-icon class="mr-1" :icon="['fa', 'exclamation-triangle']"/>
        <span>Es ist ein Fehler aufgetreten.</span>
    </div>

    <div v-if="isLoading" class="lmt-loading mt-2"></div>

    <div v-if="isBilled" class="whitespace-no-wrap text-center mt-2">
        <font-awesome-icon class="mr-1 -mb-0.5 text-green-500 text-xl" :icon="['fa', 'check-circle']"/>
        <span>Abgerechnet</span>
    </div>
</template>

<script lang="ts">

import axios from "axios";

import { defineComponent } from "vue";

export default defineComponent({
    emits: ['vendor-update'],

    props: {
        vendorId: Number,
        month: String,
        unbilledTicketsCount: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            hasError: false,
            isLoading: false,
            isBilled: this.unbilledTicketsCount === 0
        }
    },

    methods: {
        setToBilled() {
            this.hasError = false;
            this.isLoading = true;
            
            const url = `/setToBilled/${this.vendorId}`;

            axios.post(url, {
                    month: this.month
                })
                .then(response => {
                    this.isBilled = response.data.unbilled_tickets_count === 0
                    this.$emit('vendor-update', response.data)
                }).catch(() => {
                    this.hasError = true
                }).finally(() => {
                    this.isLoading = false
                })
        }
    }
})

</script>