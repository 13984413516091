<template>
    <div :class="$attrs.class">
        <h2><span v-html="modelName"></span>spezifische Fragen</h2>
        <abstract-manager modelName="question" displayName="Frage" :initialModels="initialQuestions">
            <template v-slot:default="slotProps: any">
                <div class="lmt-form-group flex flex-grow">
                    <input :name="`questions[${slotProps.index}][name]`" type="text" v-model="slotProps.question.name">
                </div>
            </template>
        </abstract-manager>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractManager from "./AbstractManager.vue";
import { AbstractManagedModel } from "../../typings/types";

type Question = AbstractManagedModel

export default defineComponent({
    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        modelName: {
            type: String,
            required: true,
        },
        initialQuestions: {
            type: Array as () => Array<Question>,
            default: new Array<Question>()
        }
    },
})

</script>
