<template>
    <div class="flex justify-between gap-1">
        <div>
            <input
                type="radio"
                name="lead_quality"
                :id="`lead_quality_prime-${lead.id}`"
                class="hidden peer"
                value="prime"
                :checked="lead.lead_quality === 'prime'"
                @change="$emit('change')">

            <label 
                :for="`lead_quality_prime-${lead.id}`"
                class="cursor-pointer py-1 px-2 w-16 text-center border border-gray-300 bg-white hover:bg-gray-50 shadow-md hover:shadow-lg peer-checked:shadow-none peer-checked:bg-sky-900/40 text-gray-400 peer-checked:text-gray-800 peer-checked:border-gray-700">
                
                Prime
            </label>
        </div>

        <div>
            <input
                type="radio"
                name="lead_quality"
                :id="`lead_quality_second-${lead.id}`"
                class="hidden peer"
                value="second"
                :checked="lead.lead_quality === 'second'"
                @change="$emit('change')">

            <label 
                :for="`lead_quality_second-${lead.id}`"
                class="cursor-pointer py-1 px-2 w-16 text-center border border-gray-300 bg-white hover:bg-gray-50 shadow-md hover:shadow-lg peer-checked:shadow-none peer-checked:bg-sky-900/40 text-gray-400 peer-checked:text-gray-800 peer-checked:border-gray-700">
                
                Second
            </label>
        </div>

        <div>
            <input
                type="radio"
                name="lead_quality"
                :id="`lead_quality_third-${lead.id}`"
                class="hidden peer"
                value="third"
                :checked="lead.lead_quality === 'third'"
                @change="$emit('change')">

            <label 
                :for="`lead_quality_third-${lead.id}`"
                class="cursor-pointer py-1 px-2 w-16 text-center border border-gray-300 bg-white hover:bg-gray-50 shadow-md hover:shadow-lg peer-checked:shadow-none peer-checked:bg-sky-900/40 text-gray-400 peer-checked:text-gray-800 peer-checked:border-gray-700">
                
                Third
            </label>
        </div>

        <div>
            <input
                type="radio"
                name="lead_quality"
                :id="`lead_quality_null-${lead.id}`"
                class="hidden peer"
                value=""
                :checked="isNull(lead.lead_quality)"
                @change="$emit('change')">

            <label 
                :for="`lead_quality_null-${lead.id}`"
                class="cursor-pointer py-1 px-2 w-16 text-center border border-gray-300 bg-white hover:bg-gray-50 shadow-md hover:shadow-lg peer-checked:shadow-none peer-checked:bg-sky-900/40 text-gray-400 peer-checked:text-gray-800 peer-checked:border-gray-700">
                
                -
            </label>
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import { Lead } from "../../typings/types.js";

export default defineComponent({
    props: {
        lead: Object as () => Lead
    }
})
</script>