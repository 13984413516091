export type AbstractManagedModel = {
    id: number|null,
    unique_id?: number,
    is_marked_for_destruction?: Boolean,
    pivot?: object,
}

export type Contract = AbstractManagedModel & {
    contract_number: string,
    sevdesk_id: string,
    start_at: string,
    expire_at: string,
    price_per_lead: number,
    base_fee: number,
    max_leads_per_month: number,
    auto_lead_delivery: number,
}

export type Customer = AbstractManagedModel & {
    name: string,
}

export type Lead = {
    id: number,
    sectors: Array<Sector>,
    lead_quality: string,
    protocol_entries: Array<ProtocolEntry>
}

export type ProtocolEntry = {
    id: number
}

export type Ticket = {
    id: number,
    lead_id: number,
    vendor_id: number,
    lead: Lead,
    price: number
}

export type User = {
    id: number,
    full_name: string,
    approved_at: string|null,
    role: string,
    email: string,
    phone: string,
}

export type Tag = AbstractManagedModel & {
    name: string,
}

export type Sector = AbstractManagedModel & {
    name: string,
    features: Array<Feature>,
    vendors: Array<Vendor>,
    wizard_questions: Array<Question>
}

export type City = AbstractManagedModel & {
    name: string,
}

export type ItServiceProvider = AbstractManagedModel & {
    name: string,
}

export type Vendor = AbstractManagedModel & {
    name: string|null,
    contracts?: Array<Contract>,
    features?: Array<Feature>,
    tickets: Array<Ticket>,
    isRejected?: boolean,
    users_exists?: boolean,
    current_contract?: Contract,
    current_contract_exists: boolean,
    contracts_exists: boolean,
    calendar_url: string,
    onboarding_url: string,
}

export type Feature = AbstractManagedModel & {
    name: string,
    pivot?: {
        sector_id: number
    }
}

export type Question = {
    id: number,
    question: string,
    type: QuestionTypes,
    upper_limit?: number,
    response: any,
    feature: Feature,
    options: Array<QuestionOption>,
    lead_answer: LeadAnswer,
}

type QuestionOption = {
    id: number,
    name: string
}

export enum QuestionTypes {
    Range = 'range',
    Text = 'text',
    Textarea = 'textarea',
    Boolean = 'boolean',
    Dropdown = 'dropdown',
}

export type LeadAnswer = {
    id?: number,
    answer: string
}
