export default {
    methods: {
        isUndefined(value: any): boolean {
            return typeof value === 'undefined';
        },

        isNull(value: any): boolean {
            return this.isUndefined(value) || value === null
        },

        isEmpty(value: Array<any>|string): boolean {
            return this.isNull(value) || value.length === 0
        },

        isInArray(array: Array<any>, element: any): boolean {
            return array.indexOf(element) > -1
        },

        capitalizeFirst(string: string): string {
            return string.charAt(0).toUpperCase() + string.slice(1)
        },

        dateTimeFormat(dateString: string): string {
            if (this.isNull(dateString)) {
                return null
            }

            let date = new Date(dateString);

            return date.toLocaleDateString('de-DE', {
                hour: '2-digit',
                minute: '2-digit',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'Europe/Berlin'
            });
        },

        dateFormat(dateString: string): string {
            if (this.isNull(dateString)) {
                return null
            }

            let date = new Date(dateString);

            return date.toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                timeZone: 'Europe/Berlin'
            });
        },

        timeFormat(dateString: string): string {
            if (this.isNull(dateString)) {
                return null
            }

            let date = new Date(dateString);

            return date.toLocaleTimeString('de-DE', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'Europe/Berlin'
            });
        }
    }
}