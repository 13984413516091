import { library } from '@fortawesome/fontawesome-svg-core'

import { 
    faCaretUp,
    faCaretDown,
    faCaretRight,
    faExclamationTriangle,
    faCheckCircle,
    faCheck,
    faTimes,
    faBan,
    faEdit,
    faInfoCircle,
    faPhone,
    faCalendar,
    faEnvelope,
    faPlusSquare,
    faPhoneVolume,
    faPhoneSlash,
    faEnvelopeCircleCheck,
    faHandshake,
    faArrowRotateBack,
    faCalendarPlus,
    faPlus,
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faCaretUp,
    faCaretDown,
    faCaretRight,
    faExclamationTriangle,
    faCheckCircle,
    faCheck,
    faTimes,
    faBan,
    faEdit,
    faInfoCircle,
    faPhone,
    faCalendar,
    faCalendarPlus,
    faEnvelope,
    faEnvelopeCircleCheck,
    faPlus,
    faPlusSquare,
    faPhoneVolume,
    faPhoneSlash,
    faHandshake,
    faArrowRotateBack
)