<template>
    <div :class="$attrs.class">
        <h2>Vergleichsseiten</h2>
        <abstract-manager
            modelName="tag"
            displayName="Vergleichsseite"
            :initialModels="initialTags"
            inputListClasses="grid grid-cols-3 gap-4"
            :deleteButtonClasses="'mt-1'">

            <template v-slot:default="slotProps: Tag">
                <div class="lmt-form-group flex flex-grow">
                    <input :name="`tags[${slotProps.index}][name]`" type="text" v-model="slotProps.tag.name">
                </div>
            </template>
        </abstract-manager>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractManager from "./AbstractManager.vue";

export default defineComponent({
  computed: {
    Tag() {
      return Tag
    }
  },
    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        initialTags: {
            type: Array as () => Array<Tag>,
            default: new Array<Tag>({id: null, name: ''})
        }
    }
})

</script>
