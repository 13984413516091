<template>
    <div :class="$attrs.class">
        <h2 v-html="title"></h2>
        <abstract-manager
            modelName="city"
            displayName="Stadt"
            :initialModels="selectedCities"
            :selectableModels="selectableCities"
            :usesDefaultAddModelButton="false"
            :deleteButtonClasses="'mt-1'"
            :newModelId="newCityId"
            :inputListClasses="!isNull(oldFeatures) ? '' : 'grid grid-cols-3 gap-4'"
            ref="abstractManagerRef">

            <template v-slot:default="slotProps: City">
                <div class="flex flex-wrap pt-1">
                    <h3 :class="{'w-1/2 lg:w-1/3 2xl:w-1/4': !isNull(oldFeatures)}" v-html="slotProps.city.name"></h3>
                    <input :name="`cities[${slotProps.city.id}][name]`" type="hidden" :value="slotProps.city.name">
                </div>
            </template>

            <template v-slot:selector>
                <select class="mt-2 w-full" v-model="newCityId" @change="triggerSelection">
                    <option :value="null" v-html="'Wähle eine Stadt aus'" hidden></option>
                    <option v-for="city in selectableCities" :value="city.id" v-html="city.name"></option>
                </select>
            </template>

        </abstract-manager>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractManager from "./AbstractManager.vue";
import { City, Feature } from "../../typings/types";
import mixins from "../../mixins";

type ManageCityData = {
    newCityId: number|null,
    selectedCities: Array<City>
}

export default defineComponent({
    mixins: [mixins],

    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        cities: {
            type: Array as () => Array<City>,
            required: true,
        },
        initialSelectedCities: {
            type: Array as () => Array<City>,
            default: new Array<City>()
        },
        oldFeatures: {
            type: Array as () => Array<Feature>,
            default: null,
        },
        title: {
            type: String,
            default: 'Verwandte Branchen',
            required: false,
        }
    },
    
    data(): ManageCityData {
        return {
            newCityId: null,
            selectedCities: this.initialSelectedCities,
        }
    },

    computed: {
        selectableCities: function() {
            return this.cities.filter(city => {
                const hasBeenSelected = this.isUndefined(this.selectedCities.find(selectedCity => {
                    return selectedCity.id === city.id && !selectedCity.is_marked_for_destruction
                }))

                return hasBeenSelected;
            });
        }
    },

    beforeMount() {
        if (!this.isNull(this.oldFeatures) && this.oldFeatures.length > 0) {
            this.selectedCities = this.initialSelectedCities.map(city => {
                city.features = this.oldFeatures.filter(feature => {
                    return feature.pivot!.city_id === city.id
                })

                return city
            })
        }
    },

    methods: {
        triggerSelection() {
            (this.$refs.abstractManagerRef as typeof AbstractManager).addModel(this.newCityId);

            this.newCityId = null;
        }
    }
})

</script>
