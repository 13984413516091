<template>
    <button v-if="isReturned" class="px-2 py-1 -my-1 bg-blue-400 hover:bg-blue-500 text-white" @click="updateTicketStatus('rejectReturn')">Berechnen</button>
    <button v-if="!isReturned" class="px-2 py-1 -my-1 bg-yellow-400 hover:bg-yellow-500 text-white" @click="updateTicketStatus('return')">Zurücknehmen</button>
</template>

<script lang="ts">

import axios from "axios";
import mixins from "../../mixins";

import { defineComponent } from "vue";

export default defineComponent({
    mixins: [mixins],

    emits: ['vendor-update'],

    props: {
        initialTicket: Object,
        vendorId: Number,
        month: String,
    },

    data() {
        return {
            ticket: this.initialTicket
        }
    },

    computed: {
        isReturned: function() {
            return !this.isNull(this.ticket.returned_at) && this.isNull(this.ticket.return_rejected_at);
        }
    },

    methods: {
        updateTicketStatus(action: string) {
            const url = `${window.location.origin}/tickets/${this.ticket!.id}/${action}`

            axios.put(url, {
                'vendorId': this.vendorId,
                'month': this.month
            }).then(response => {
                this.ticket = response.data.tickets.find(ticket => {
                    return ticket.id === this.ticket.id
                })

                this.$emit('vendor-update', response.data)
            })
        }
    }
})

</script>