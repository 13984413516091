<template>
    <div :id="id" class="relative">
        <input class="border-gray-300"
            type="text" 
            v-model="needle"
            @input="filterOptions"
            @keydown.up.down="navigate"
            @keydown.enter.prevent="select()"
            @focus="toggleExpansion(true)"
            @click="toggleExpansion(true)"
            @blur="unfocus()">

        <input type="hidden" :name="name" v-model="selectedId">

        <div class="absolute top-0 right-0 py-1 px-3 cursor-pointer text-gray-500" @click="toggleExpansion()">
            <font-awesome-icon :icon="['fa', 'caret-down']"></font-awesome-icon>
        </div>

        <div class="absolute bg-white border border-color-gray-600 w-full max-h-96 z-50 overflow-y-scroll shadow-2xl" :class="{'hidden': !isExpanded && !hasFocus()}">
            <div v-for="(option, index) in filteredOptions"
                class="cursor-pointer hover:bg-gray-300 py-1 px-3"
                :class="{'bg-gray-300': option.isFocused}"
                @mousedown.prevent
                @click="select(index)">

                <span v-html="option.name"></span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

type SelectInputData = {
    id: string,
    filteredOptions: Array<Option>,
    isExpanded: Boolean,
    needle: string
}

type Option = {
    name: string,
    isFocused?: boolean
}

enum Direction {
    Up,
    Down
}

export default defineComponent({
    emits: ['update:model-value', 'change'],

    props: {
        options: Array as () => Array<Option>,
        modelValue: [Number, String],
        name: String,
    },

    data(): SelectInputData {
        return {
            id: this.name.replace(/[\[\]]/g, ''),
            filteredOptions: this.options,
            isExpanded: false,
            needle: '',
        }
    },

    mounted() {
        this.updateNeedle()

        window.addEventListener('click', event => {
            if((event.target as HTMLElement).closest(`#${this.id}`) !== null) {
                return
            }

            this.toggleExpansion(false);
        });
    },

    computed: {
        selectedId: {
            get() {
                return this.modelValue
            },

            set(newSelectedId) {
                this.$emit('update:model-value', newSelectedId)
            }
        }
    },

    watch: {
        selectedId() {
            this.updateNeedle()
        }
    },

    methods: {
        updateNeedle() {
            const selectedOption = this.options.find(option => {
                return option.id === Number(this.selectedId)
            })?.name

            this.needle = selectedOption ?? ''
        },

        filterOptions() {
            this.unselect()

            if (this.needle.length === 0) {
                this.filteredOptions = this.options

                return
            }

            this.filteredOptions = this.options.filter((option: Option) => {
                return option.name.toLowerCase().indexOf(this.needle.toLowerCase()) > -1
            })

            if (this.getCurrentFocusedIndex() === -1) {
                this.focus(Direction.Down)
            }
        },

        navigate(event) {
            if (event.keyCode === 40) {
                this.focus(Direction.Down)
            }

            if (event.keyCode === 38) {
                this.focus(Direction.Up)
            }
        },

        focus(direction: Direction) {
            if (this.isEmpty(this.filteredOptions)) {
                return
            }

            const focusedOptionIndex = this.getCurrentFocusedIndex()

            if (focusedOptionIndex === -1) {
                this.filteredOptions[0].isFocused = true

                return;
            }

            let newFocusedOptionIndex;

            if (direction === Direction.Down) {
                newFocusedOptionIndex = focusedOptionIndex >= this.filteredOptions.length - 1 ? 0 : focusedOptionIndex + 1
            }

            if (direction === Direction.Up) {
                newFocusedOptionIndex = focusedOptionIndex === 0 ? this.filteredOptions.length - 1 : focusedOptionIndex - 1
            }

            this.filteredOptions[focusedOptionIndex].isFocused = false
            this.filteredOptions[newFocusedOptionIndex].isFocused = true
        },

        unfocus() {
            this.filteredOptions.forEach((option: Option) => {
                option.isFocused = false
            })

            this.isExpanded = false
        },

        getCurrentFocusedIndex(): number {
            return this.filteredOptions.findIndex((option: Option) => {
                return option.isFocused
            })
        },

        hasFocus(): boolean {
            return this.getCurrentFocusedIndex() > -1
        },

        select(index?: number) {
            if (this.isNull(index)) {
                index = this.getCurrentFocusedIndex()
            }

            if (index === -1) {
                return
            }

            this.unfocus()

            this.filteredOptions[index].isSelected = true
            this.selectedId = this.filteredOptions[index].id

            this.$emit('change', this.selectedId)

            this.toggleExpansion(false)
        },

        unselect() {
            this.selectedId = null
        },

        toggleExpansion(isExpanded: boolean|null = null) {
            this.isExpanded = !this.isNull(isExpanded) ? isExpanded : !this.isExpanded
        }
    }
})

</script>
