<template>
    <div>
        <div>

        </div>

        <table class="lmt-table w-full">
            <tr class="select-none">
                <th @click="orderUsersBy('software_name')" class="cursor-pointer ">
                    Name
                    <font-awesome-icon v-if="isSortIconVisible('software_name')" :icon="sortIcon"/>
                </th>
                <th @click="orderUsersBy('vendor_name')" class="cursor-pointer ">
                    Anbietername
                    <font-awesome-icon v-if="isSortIconVisible('vendor_name')" :icon="sortIcon"/>
                </th>
                <th @click="orderUsersBy('sectors')" class="cursor-normal">
                    Branchen
                    <!-- <font-awesome-icon v-if="isSortIconVisible('sectors')" :icon="sortIcon"/> -->
                </th>

                <template v-if="hasContracts">
                    <th @click="orderUsersBy('contracts.expire_at')">
                        Vertragsende
                    </th>
                    <th @click="orderUsersBy('contracts.base_fee')">
                        Grundgebühr
                    </th>
                    <th @click="orderUsersBy('contracts.price_per_lead')">
                        Preis/Lead
                    </th>
                    <th @click="orderUsersBy('contracts.max_leads_per_month')">
                        Leads/Monat
                    </th>
                    <th @click="orderUsersBy('contracts.auto_lead_delivery')">
                        Leadandienung 
                    </th>
                </template>

                <th></th>
            </tr>

            <tr v-for="vendor in vendors">
                <td>
                    <span v-html="vendor.software_name"></span>
                </td>
                <td>
                    <span v-html="vendor.vendor_name"></span>
                </td>
                <td>
                    <div v-for="sector in vendor.sectors" v-html="sector.name" class="text-sm" ></div>
                </td>

                <template v-if="hasContracts">
                    <td v-html="dateFormat(vendor.contracts[0]?.expire_at) ?? 'k.A.'"></td>
                    <td v-html="vendor.contracts[0]?.base_fee ?? '-'"></td>
                    <td v-html="vendor.contracts[0]?.price_per_lead ?? '-'"></td>
                    <td v-html="vendor.contracts[0]?.max_leads_per_month ?? '-'"></td>
                    <td v-html="vendor.contracts[0]?.auto_lead_delivery ?? '-'"></td>
                </template>

                <td>
                    <a :href="`/vendors/${vendor.id}/edit`" class="w-6 inline-block mr-4">
                        <img src="/img/icon-edit.png" alt="Bearbeiten">
                    </a>
                    <a :href="`/vendors/${vendor.id}/delete`" class="w-6 inline-block">
                        <img src="/img/icon-delete.png" alt="">
                    </a>
                </td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">

import mixins from "../../mixins";
import tableMixins, { SortDirection } from "../../tableMixins";

import { defineComponent } from "vue";
import { Vendor } from "../../typings/types";

export default defineComponent({
    mixins: [mixins, tableMixins],
    
    props: {
        initialVendors: {
            type: Array as () => Array<Vendor>,
            required: true
        }
    },

    data() {
        return {
            vendors: this.initialVendors,
            sortDirection: null,
            currentColumn: null,
            hasContracts: !this.isUndefined(this.initialVendors[0].contracts)
        }
    },

    computed: {
        sortIcon: function () {
            if (this.sortDirection === SortDirection.ASC) {
                return ['fa', 'caret-up']
            }

            return ['fa', 'caret-down']
        },
    },

    methods: {
        orderUsersBy(column) {
            this.vendor = this.orderBy(this.vendors, column, 'name')
        }
    }
})

</script>