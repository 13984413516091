<template>
    <div>
        <div>

        </div>

        <table class="lmt-table w-full">
            <tr class="cursor-pointer select-none">
                <th @click="orderUsersBy('full_name')">
                    Name
                    <font-awesome-icon v-if="isSortIconVisible('full_name')" :icon="sortIcon"/>
                </th>
                <th @click="orderUsersBy('role')">
                    Rolle
                    <font-awesome-icon v-if="isSortIconVisible('role')" :icon="sortIcon"/>
                </th>
                <th @click="orderUsersBy('phone')">
                    Telefon
                    <font-awesome-icon v-if="isSortIconVisible('phone')" :icon="sortIcon"/>
                </th>
                <th @click="orderUsersBy('email')">
                    Email
                    <font-awesome-icon v-if="isSortIconVisible('email')" :icon="sortIcon"/>
                </th>
                <th @click="orderUsersBy('approved_at')">
                    <div class="text-center w-full">Bestätigt</div>
                    <font-awesome-icon v-if="isSortIconVisible('approved_at')" :icon="sortIcon"/>
                </th>
                <th></th>
            </tr>

            <tr v-for="user in users">
                <td>
                    <span v-html="user.full_name" class="mb-1 block"></span>
                    <span v-html="user.company_name" class="text-xs block ml-1"></span>
                </td>
                <td>
                    <span v-html="user.role_translated" class="mb-1 block"></span>
                    <span v-for="vendor in user.vendors" v-html="vendor.name" class="text-xs block ml-1"></span>
                </td>
                <td v-html="user.phone"></td>
                <td v-html="user.email"></td>
                <td>
                    <font-awesome-icon class="mx-auto block text-xl w-5 text-green-700" :icon="['fa', 'check']" v-if="!isNull(user.approved_at)"/>
                    <font-awesome-icon class="mx-auto block text-xl w-full text-red-700" :icon="['fa', 'times']" v-if="isNull(user.approved_at)"/>
                </td>
                <td class="w-20">
                    <a :href="`/users/${user.id}/edit`">
                        <img src="/img/icon-edit.png" alt="Bearbeiten" class="pr-4">
                    </a>
                </td>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">

import mixins from "../../mixins";
import tableMixins, { SortDirection } from "../../tableMixins";

import { defineComponent } from "vue";

export default defineComponent({
    mixins: [mixins, tableMixins],
    
    props: {
        initialUsers: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            users: this.initialUsers,
            sortDirection: null,
            currentColumn: null,
        }
    },

    computed: {
        sortIcon: function () {
            if (this.sortDirection === SortDirection.ASC) {
                return ['fa', 'caret-up']
            }

            return ['fa', 'caret-down']
        },
    },

    methods: {
        orderUsersBy(column) {
            this.user = this.orderBy(this.users, column, 'role')
        }
    }
})

</script>