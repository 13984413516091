<template>
    <div :class="$attrs.class">
        <h2>Vertrag</h2>
        <abstract-manager modelName="contract"
            displayName="Vertrag"
            :initialModels="contracts"
            :maxModelCount="1"
            :errorIndexes="errorIndexes"
            :deleteButtonClasses="'mt-7'">
            
            <template v-slot:default="slotProps: Contract">
                <div class="lmt-form-group flex-grow pr-3">
                    <label :for="`contracts[${slotProps.index}][contract_number]`">Vertragsnummer <span class="text-red-500">*</span></label>
                    <input :id="`contracts[${slotProps.index}][contract_number]`" :name="`contracts[${slotProps.index}][contract_number]`" type="text" v-model="slotProps.contract.contract_number">
                    <span class="text-red-500" v-if="hasError('contract_number', slotProps.index)" v-html="getError('contract_number', slotProps.index)"></span>
                </div>

                <div class="lmt-form-group flex-grow">
                    <label :for="`contracts[${slotProps.index}][sevdesk_id]`">sevDesk-ID <span class="text-red-500">*</span></label>
                    <input :id="`contracts[${slotProps.index}][sevdesk_id]`" :name="`contracts[${slotProps.index}][sevdesk_id]`" type="text" v-model="slotProps.contract.sevdesk_id">
                    <span class="text-red-500" v-if="hasError('sevdesk_id', slotProps.index)" v-html="getError('sevdesk_id', slotProps.index)"></span>
                </div>
            </template>

            <template v-slot:body="slotProps">
                <div class="grid grid-cols-2 gap-x-4 gap-y-1">
                    <div class="lmt-form-group">
                        <label :for="`contracts[${slotProps.index}][start_at]`">Vertragsbeginn (JJJJ-MM-TT)</label>
                        <input :id="`contracts[${slotProps.index}][start_at]`" :name="`contracts[${slotProps.index}][start_at]`" type="text" v-model="slotProps.contract.start_at">
                        <span class="text-red-500" v-if="hasError('start_at', slotProps.index)" v-html="getError('start_at', slotProps.index)"></span>
                    </div>
                    <div class="lmt-form-group">
                        <label :for="`contracts[${slotProps.index}][expire_at]`">Vertragsende (JJJJ-MM-TT)</label>
                        <input :id="`contracts[${slotProps.index}][expire_at]`" :name="`contracts[${slotProps.index}][expire_at]`" type="text" v-model="slotProps.contract.expire_at">
                        <span class="text-red-500" v-if="hasError('expire_at', slotProps.index)" v-html="getError('expire_at', slotProps.index)"></span>
                    </div>
                    <div class="lmt-form-group">
                        <label :for="`contracts[${slotProps.index}][base_fee]`">Grundgebühr in €</label>
                        <input :id="`contracts[${slotProps.index}][base_fee]`" :name="`contracts[${slotProps.index}][base_fee]`" type="text" v-model="slotProps.contract.base_fee">
                        <span class="text-red-500" v-if="hasError('base_fee', slotProps.index)" v-html="getError('base_fee', slotProps.index)"></span>
                    </div>
                    <div class="flex justify-between">
                        <div class="lmt-form-group flex-1 pr-3">
                            <label :for="`contracts[${slotProps.index}][price_per_lead]`">Preis/Lead in €</label>
                            <input :id="`contracts[${slotProps.index}][price_per_lead]`" :name="`contracts[${slotProps.index}][price_per_lead]`" type="text" v-model="slotProps.contract.price_per_lead">
                            <span class="text-red-500" v-if="hasError('price_per_lead', slotProps.index)" v-html="getError('price_per_lead', slotProps.index)"></span>
                        </div>

                        <div>
                            <label class="font-bold block">Leadflat</label>
                            <div class="lmt-form-group-radio inline-block mr-5 py-2">
                                <label :for="`contracts[${slotProps.index}][is_flat_rate][yes]`">
                                    <input :id="`contracts[${slotProps.index}][is_flat_rate][yes]`" :name="`contracts[${slotProps.index}][is_flat_rate]`" type="radio" value="1" v-model="slotProps.contract.is_flat_rate">
                                    <span>Ja</span>
                                </label>
                            </div>
                            <div class="lmt-form-group-radio inline-block py-2">
                                <label :for="`contracts[${slotProps.index}][is_flat_rate][no]`">
                                    <input :id="`contracts[${slotProps.index}][is_flat_rate][no]`" :name="`contracts[${slotProps.index}][is_flat_rate]`" type="radio" value="0" v-model="slotProps.contract.is_flat_rate">
                                    <span>Nein</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="lmt-form-group">
                        <label :for="`contracts[${slotProps.index}][max_leads_per_month]`">Max. Leads/Monat</label>
                        <input :id="`contracts[${slotProps.index}][max_leads_per_month]`" :name="`contracts[${slotProps.index}][max_leads_per_month]`" type="text" v-model="slotProps.contract.max_leads_per_month">
                        <span class="text-red-500" v-if="hasError('max_leads_per_month', slotProps.index)" v-html="getError('max_leads_per_month', slotProps.index)"></span>
                    </div>

                    <div>
                        <label class="font-bold block">Leadandienung</label>
                        <div class="lmt-form-group-radio inline-block mr-5 py-2">
                            <label :for="`contracts[${slotProps.index}][auto_lead_delivery][yes]`">
                                <input :id="`contracts[${slotProps.index}][auto_lead_delivery][yes]`" :name="`contracts[${slotProps.index}][auto_lead_delivery]`" type="radio" value="1" v-model="slotProps.contract.auto_lead_delivery">
                                <span>Ja</span>
                            </label>
                        </div>
                        <div class="lmt-form-group-radio inline-block py-2">
                            <label :for="`contracts[${slotProps.index}][auto_lead_delivery][no]`">
                                <input :id="`contracts[${slotProps.index}][auto_lead_delivery][no]`" :name="`contracts[${slotProps.index}][auto_lead_delivery]`" type="radio" value="0" v-model="slotProps.contract.auto_lead_delivery">
                                <span>Nein</span>
                            </label>
                        </div>
                    </div>
                </div>
            </template>
        </abstract-manager>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractManager from "./AbstractManager.vue";
import { Contract } from "../../typings/types";

export default defineComponent({
    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        initialContracts: {
            type: Array as () => Array<Contract>,
            default: new Array<Contract>(),
        },
        errors: {
            type: Object,
            default: {},
        },
    },

    data() {
        return {
            contracts: typeof this.initialContracts !== 'undefined' && this.initialContracts !== null ? this.initialContracts : new Array<Contract>()
        }
    },

    computed: {
        errorIndexes: function() {
            const errorKeys = Object.keys(this.errors)
            const regex = /\.(\d+)\./g
            let indexes: Array<number> = []

            errorKeys.forEach((errorKey: string) => {
                const matches = regex.exec(errorKey)

                if (matches !== null && typeof matches[1] !== 'undefined') {
                    indexes.push(Number(matches[1]));
                }
            })

            return indexes
        }
    },

    methods: {
        hasError(key: string, index: number) {
            const errorKeys = Object.keys(this.errors)

            if (errorKeys.length < 1) {
                return false
            }

            return -1 < (errorKeys.findIndex(errorKey => {
                return errorKey.indexOf(`.${index}.${key}`) > -1
            }))
        },

        getError(key: string, index: number) {
            const errorKeys = Object.keys(this.errors)

            const errorIndex = errorKeys.findIndex(errorKey => {
                return errorKey.indexOf(`.${index}.${key}`) > -1
            })

            return Object.values(this.errors)[errorIndex][0]
        }
    }
})

</script>
