<template>
    <div class="list internal">
        <div :class="inputListClasses">
            <div v-for="(model, index) in models" :class="{'hidden': model.is_marked_for_destruction}">
                <div class="flex justify-start mb-3">
                    <input :name="`${pluralize(modelName)}[${model.id ?? model.unique_id}][id]`" type="hidden" :value="model.id" v-if="model.id !== null">
                    <input :name="`${pluralize(modelName)}[${model.id ?? model.unique_id}][is_marked_for_destruction]`" type="hidden" v-model="model.is_marked_for_destruction" v-if="model.is_marked_for_destruction">
                    <input :name="`${pluralize(modelName)}[${model.id ?? model.unique_id}][unique_id]`" type="hidden" v-model="model.unique_id" v-if="model.unique_id">

                    <slot :[modelName]="model" :index="model.id ?? model.unique_id" @selection="addModel"></slot>

                    <div class="ml-2 flex" :class="deleteButtonClasses" v-if="!isFixedRelation">
                        <img class="p-1 h-8 cursor-pointer" src="/img/icon-delete.png" @click="removeModel(index, model)">
                    </div>
                </div>

                <div class="bg-gray-300 p-4 mb-8" v-if="hasBody">
                    <slot name="body" :[modelName]="model" :index="model.id ?? model.unique_id"></slot>
                </div>
            </div>
        </div>

        <slot name="selector" :newModelId="newModelId" v-if="canModelsBeAdded"></slot>
        <div class="simple-action" @click="addModel" v-if="canModelsBeAdded && !isSelector">+ <span v-html="displayName"></span> hinzufügen</div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

import mixins from "../../mixins";
import { AbstractManagedModel } from "../../typings/types";

type AbstractManagerData = {
    models: Array<AbstractManagedModel>,
    isSelector: boolean,
    expandedIndexes: Array<number>,
}

export default defineComponent({
    mixins: [mixins],

    props: {
        displayName: {
            type: String,
        },
        inputListClasses: {
            type: String
        },
        modelName: {
            type: String,
            required: true,
        },
        initialModels: {
            type: Array as () => Array<AbstractManagedModel>,
            required: true,
        },
        selectableModels: {
            type: Array as () => Array<AbstractManagedModel>,
        },
        newModelId: {
            type: Number,
            default: null,
        },
        maxModelCount: {
            type: Number,
            default: Infinity,
        },
        errorIndexes: {
            type: Array as () => Array<number>,
            default: [],
        },
        isFixedRelation: {
            type: Boolean,
            default: false,
        },
        deleteButtonClasses: {
            type: String,
            default: 'mt-3',
        }
    },

    data(): AbstractManagerData {
        return {
            models: this.initialModels,
            isSelector: !this.isUndefined(this.selectableModels),
            expandedIndexes: this.errorIndexes,
        }
    },

    computed: {
        hasBody: function(): boolean {
            return !this.isUndefined(this.$slots.body)
        },

        canModelsBeAdded: function(): boolean {
            const nonDeletedModels = Object.values(this.models).filter((model: AbstractManagedModel) => {
                return this.isNull(model.is_marked_for_destruction) || !model.is_marked_for_destruction
            })

            return !this.isFixedRelation && nonDeletedModels.length < this.maxModelCount
        }
    },

    created() {
        const isLastModelEmpty = !this.isUndefined(this.models.at(-1)) && this.isNull(this.models.at(-1)!.id)

        if (!this.isSelector && this.canModelsBeAdded && !isLastModelEmpty && this.errorIndexes.length === 0) {
            this.addModel()
        }
    },

    methods: {
        addModel(newModelId?: number) {
            newModelId = newModelId ?? this.newModelId;

            let newModel: AbstractManagedModel = {
                id: null,
                unique_id: this.generateUniqueIndex(),
            }

            if (this.isSelector) {
                newModel = this.selectableModels!.find(model => {
                    return model.id === newModelId
                })!
            }

            this.models.push(newModel)
        },

        generateUniqueIndex(modelId?: number) {
            if (!this.isNull(modelId)) {
                return modelId!
            }

            const array = new Uint32Array(1)
            crypto.getRandomValues(array)

            return array[0]
        },

        removeModel(index: number, model: AbstractManagedModel) {
            if (model.id === null) {
                this.models.splice(index, 1)
                
                return
            }

            model.is_marked_for_destruction = true
        },

        toggleExpansion(index: number) {
            if (this.isExpanded(index)) {
                this.expandedIndexes.splice(this.expandedIndexes.indexOf(index), 1)

                return
            }

            if (this.expandedIndexes.length <= 1) {
                this.expandedIndexes = []
            }

            this.expandedIndexes.push(index);
        },

        isExpanded(index: number) {
            return this.isInArray(this.expandedIndexes, index); 
        },

        pluralize(singular) {
            const pluralMap = {
                "city" : "cities"
            }

            if (!this.isUndefined(pluralMap[singular])) {
                return pluralMap[singular]
            }

            return `${singular}s`
        },
    }
})

</script>
