<template>
    <div class="fixed top-0 bottom-0 left-0 right-0 z-50" :class="[isOpen ? '' : 'hidden']" v-if="isOpen">
        <div
            class="transition transition-opacity transition-1000 delay-300 opacity-50 bg-black absolute top-0 bottom-0 left-0 right-0"
            :class="[isOpen ? 'opacity-50' : 'opacity-0']"
            @click.passive="closeModal">
        </div>

        <div class="absolute inset-x-28 bg-white top-1/2 -translate-y-1/2">
            <div class="flex justify-between lmt-bg-blue text-white pl-5">
                <h2 class="py-3">{{ title }}</h2>
                <!-- <font-awesome-icon class="pt-4 p-3 text-3xl cursor-pointer" :icon="'times'" @click.passive="closeModal"></font-awesome-icon> -->
                <button class="pt-4 p-3 text-3xl cursor-pointer" @click.passive="closeModal">X</button>
            </div>

            <div class="p-5">
                <slot name="modalBody"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

export default defineComponent({
    props: {
        isOpen: Boolean,
        title: String
    },

    data() {
        return {
        }
    },

    methods: {
        closeModal() {
            this.$emit('closeModal', false)
        }
    }
})

</script>

<style>

</style>