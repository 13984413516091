<template>
    <div class="flex justify-between items-end gap-2 mb-1">
        <div class="lmt-form-group-sm flex-1">
            <label class="!text-base" for="sector_id">Branche</label>

            <!-- <select-input class="border-gray-300"  name="sector_id" id="sector_id" :options="sectors" :model-value="lead.sector_id" v-model="lead.sector_id" @change="setSector"></select-input> -->

            <select name="sector_id" id="sector_id" class="border-gray-300" v-model="selectedSectorId" @change="setSector()">
                <option :value="null" hidden disabled>Wähle eine Branche aus</option>
                <option v-for="sector in sectors" :value="sector.id" v-html="sector.name"></option>
            </select>
        </div>

        <button
            class="border border-gray-300 bg-white text-md text-gray-500 py-0.5 px-2"
            :class="{'bg-gray-300 text-gray-400': isNull(selectedSectorId)}"
            :disabled="isNull(selectedSectorId)"
            @click.prevent="addSector">


          <!-- replace with emoji  <font-awesome-icon :icon="'plus'"></font-awesome-icon> -->
          ➕
        </button>
    </div>

    <!-- <div v-if="hasMultipleSectors" class="flex flex-wrap gap-1 text-sm">
        <div v-for="sector in selectedSectors" class="border border-gray-400 bg-gray-50 pl-1">
            <input type="hidden" :name="`sectors[id]`" :value="sector.id">

            <span v-html="sector.name"></span>
            <font-awesome-icon :icon="'times'" class="ml-0.75 -mb-[5px] p-1 cursor-pointer" @click="removeSector(sector.id)"></font-awesome-icon>
        </div>
    </div> -->

    <div v-for="sector in selectedSectors">
        <input type="hidden" :name="`sectors[${sector.id}][id]`" :value="sector.id">

        <div class="flex justify-between text-base border-b border-gray-400 py-0.5 items-center mt-5">
            <div v-if="hasMultipleSectors" class="flex-1 mr-3 cursor-pointer select-none font-bold" @click="toggleSector(sector.id)">
                <!-- <font-awesome-icon class="mr-2 transform transition-transform" :class="{'rotate-90': expandedSector === sector.id}" :icon="['fa', 'caret-right']"/> -->
                <p class="mr-2 transform transition-transform" :class="{'rotate-90': expandedSector === sector.id}">
                    ➡️
                </p>
                <span v-html="sector.name"></span>
            </div>

            <div v-if="unansweredQuestionsCount(sector.id)" class="text-red-700/80 text-sm">
                <!-- <font-awesome-icon :icon="'exclamation-triangle'" class="mr-1"></font-awesome-icon> -->
                <p class="mr-1">⚠️</p>
                <span v-html="`${unansweredQuestionsCount(sector.id)} von ${sector.wizard_questions.length} Fragen unbeantwortet`"></span>
            </div>

            <div v-if="!unansweredQuestionsCount(sector.id)" class="text-green-700/80 text-sm">
                <font-awesome-icon :icon="'check'" class="mr-1"></font-awesome-icon>
                <span>Alle Fragen beanwortet</span>
            </div>

            <!-- <font-awesome-icon v-if="selectedSectors.length > 1" :icon="'times'" class="ml-20 py-1 cursor-pointer" @click="removeSector(sector.id)"></font-awesome-icon> -->
            <button v-if="selectedSectors.length > 1" class="border border-gray-300 bg-white text-md text-gray-500 py-0.5 px-2" @click.prevent="removeSector(sector.id)">
                ❌
            </button>
        </div>

        <div v-if="selectedSectors.length === 1 || expandedSector === sector.id" class="grid grid-cols-1 gap-2 mt-2">
            <div 
                v-for="(question, index) in sector.wizard_questions"
                class="border border-gray-50 px-2 py-1 bg-gray-50 shadow-md hover:bg-white hover:shadow-lg"
                :class="{'border-dashed border-red-400 text-red-700/80' : isEmpty(question.lead_answer?.answer)}">

                <input type="hidden" :name="`lead_answers[${question.id}][id]`" :value="question.lead_answer?.id">
                <input type="hidden" :name="`lead_answers[${question.id}][question_id]`" :value="question.id">

                <div v-if="question.type === QuestionTypes.Boolean">
                    <div class="lmt-form-group-sm mb-0">
                        <label v-html="`${index + 1}. ${question.question}`" class="block"></label>

                        <div class="flex justify-between pt-1.5 sm:pt-1">
                            <button
                                :class="[question.lead_answer?.answer === 1 ? 'lmt-btn-success' : 'lmt-btn-inactive']"
                                class="!text-sm lmt-btn-xs w-2/5 !px-0"
                                @click.passive="setAnswer(question.id, 1)"
                                type="button">

                                Ja
                            </button>
                            <button
                                :class="[!isNull(question.lead_answer?.answer) && question.lead_answer?.answer === 0 ? 'lmt-btn-success' : 'lmt-btn-inactive']"
                                class="!text-sm lmt-btn-xs w-2/5 !px-0"
                                @click.passive="setAnswer(question.id, 0)"
                                type="button">

                                Nein
                            </button>

                            <input type="hidden" :name="`lead_answers[${question.id}][answer]`" :value="question.lead_answer?.answer">
                        </div>
                    </div>
                </div>

                <div v-if="question.type === QuestionTypes.Range">
                    <div class="lmt-form-group-xs font-bold">
                        <label class="mb-1 !text-sm" for="" v-html="`${index + 1}. ${question.question}`"></label>

                        <div class="flex justify-between relative">
                            <label class="w-16 py-1.5">1</label>

                            <div class="absolute h-1.5 w-px bg-black top-6 left-0.5"></div>
                            <div class="absolute h-1.5 w-px bg-black top-6 right-1"></div>

                            <input
                                class="!w-16 text-center py-0.25 px-2 font-normal border"
                                :name="`lead_answers[${question.id}][answer]`"
                                v-model="question.lead_answer.answer"
                                @change="setAnswer(question.id)"
                                placeholder="0">

                            <label class="w-16 text-right py-1.5">> <span v-html="question.upper_limit"></span></label>
                        </div>

                        <input
                            type="range"
                            min="1"
                            :max="question.upper_limit"
                            v-model="question.lead_answer.answer"
                            @change="setAnswer(question.id)"
                            data-rules="required"
                            class="d-block text-xs p-0 w-full">

                    </div>
                </div>

                <div v-if="question.type === QuestionTypes.Text">
                    <div class="lmt-form-group-sm">
                        <label for="" v-html="`${index + 1}. ${question.question}`"></label>

                        <input type="text"
                            data-rules="required"
                            :name="`lead_answers[${question.id}][answer]`"
                            class="d-block form-control w-full border !border-solid border-gray-300 px-2 sm:px-3 py-1 sm:py-2"
                            v-model="question.lead_answer.answer">
                    </div>
                </div>

                <div v-if="question.type === QuestionTypes.Dropdown">
                    <div class="lmt-form-group-sm">
                        <label for="" v-html="`${index + 1}. ${question.question}`"></label>

                        <select-input :options="question.feature.options" :name="`lead_answers[${question.id}][answer]`" v-model="question.lead_answer.answer"></select-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";

import { Store, useStore } from 'vuex'
import { key, RootStoreInterface } from "../../Store";
import { LeadMap } from "../../Store/Lead";

import SelectInput from "@/components/Utilities/SelectInput.vue"

import { Question, QuestionTypes, Sector } from "../../typings/types"

type QuestionsData = {
    QuestionTypes: Object,
    selectedSectors: Array<Sector>,
    selectedSectorId: number,
    hasMultipleSectors: boolean,
    expandedSector: number|null,
    store: Store<RootStoreInterface>
}

export default defineComponent({
    components: {'select-input': SelectInput},

    props: {
        lead: {
            type: Object,
            required: true
        },
        sectors: {
            type: Array,
            required: true,
        }
    },

    data(): QuestionsData {
        return {
            QuestionTypes,
            selectedSectors: this.lead.sectors,
            selectedSectorId: 0,
            hasMultipleSectors: this.lead.sectors.length > 1,
            expandedSector: null,
            store: useStore(key)
        }
    },

    beforeMount() {
        this.setupQuestions()
    },

    mounted() {
        this.store.commit(LeadMap.mutations.setSectors, this.selectedSectors)
        this.setSelectedSectorId()
    },

    methods: {
        toggleSector(sectorId) {
            this.expandedSector = this.expandedSector === sectorId ? null : sectorId
        },

        setAnswer(questionId: number, answer: any) {
            this.selectedSectors.forEach((sector: Sector) => {
                let question = sector.wizard_questions.find((question: Question) => {
                    return question.id === questionId
                })

                if (this.isUndefined(question)) {
                    return
                }

                if (question.type === QuestionTypes.Range && Number(answer) >= question.upper_limit) {
                    question.lead_answer.answer = `> ${question.upper_limit}`
                }

                if (!this.isUndefined(answer)) {
                    question.lead_answer.answer = answer
                }
            })
        },

        restrictRange(question: Question) {
            if (question.type === QuestionTypes.Range && Number(question.lead_answer.answer) >= question.upper_limit) {
                question!.lead_answer.answer = `> ${question.upper_limit}`
            }
        },

        setupQuestions() {
            this.selectedSectors.forEach((sector: Sector) => {
                sector.wizard_questions.forEach(question => {
                    if (this.isUndefined(question.lead_answer?.answer)) {
                        question.lead_answer = {'answer': ''}
                    }
                })
            })
        },

        setSelectedSectorId() {
            this.selectedSectorId = this.selectedSectors?.length === 1 ? this.selectedSectors[0].id : null
        },

        unansweredQuestionsCount(sectorId: number) {
            let sector = this.selectedSectors.find((sector: Sector) => sector.id === sectorId)

            return sector.wizard_questions.filter((question: Question) => this.isEmpty(question.lead_answer.answer)).length
        },

        addSector() {
            if (!this.hasMultipleSectors) {
                this.hasMultipleSectors = true
                this.selectedSectorId = null

                return
            }

            if (this.lead.sectors.find((sector: Sector) => sector.id === Number(this.selectedSectorId))) {
                return
            }

            this.updateSectorArray()

            this.selectedSectorId = null
        },

        setSector() {
            if (this.hasMultipleSectors) {
                return
            }

            this.lead.sectors.shift()
            this.updateSectorArray()
        },

        updateSectorArray() {
            const newSector = this.sectors.find(sector => {
                return sector.id === Number(this.selectedSectorId)
            })

            this.selectedSectors.push(newSector)

            this.lead.sectors = this.selectedSectors
            this.store.commit(LeadMap.mutations.setSectors, this.selectedSectors)
            this.questions = this.setupQuestions()

            this.expandedSector = newSector.id
        },

        removeSector(sectorId: number) {
            this.selectedSectors = this.selectedSectors.filter((sector: Sector) => sector.id !== sectorId)
            this.lead.sectors = this.selectedSectors
            this.store.commit(LeadMap.mutations.setSectors, this.selectedSectors)

            if (this.selectedSectors.length === 1) {
                this.hasMultipleSectors = false
                this.selectedSectorId = this.selectedSectors[0].id
            }
        },
    }
})

</script>
