<template>
    <div class="flex justify-between -mb-[2px]" :class="$attrs.class">
        <input type="hidden" name="data_transfer_accepted" :value="0">

        <input 
            type="checkbox"
            name="data_transfer_accepted"
            id="data_transfer_accepted"
            class="block"
            :value="1"
            :checked="lead.data_transfer_accepted"
            v-model="lead.data_transfer_accepted"
            @change="updateIsDataTransferAcceptedState">

        <label for="data_transfer_accepted" class="ml-2 -mt-1 select-none block font-bold">Der Interessent ist mit der Weitergabe seiner persönlichen Daten einverstanden.</label>
    </div>

    <template v-for="sector in sectors">
        <div v-if="sectors.length <= 1" class="h-9"></div>

        <div v-if="sectors.length > 1" class="flex justify-between text-base border-b border-gray-400 px-1 py-0.5 items-center mt-5">
            <div class="flex-1 mr-3 cursor-pointer select-none font-bold" @click="toggleSector(sector.id)">
                <font-awesome-icon class="mr-2 transform transition-transform" :class="{'rotate-90': expandedSector === sector.id}" :icon="['fa', 'caret-right']"/>
                <span v-html="sector.name"></span>
            </div>
        </div>

        <table v-if="expandedSector === sector.id" class="w-full text-sm shadow-md border border-gray-300 mt-2">
            <tr class="bg-sky-900/40">
                <th class="py-0.5 px-2 text-left">Software</th>
                <th class="py-0.5 px-2 text-left">
                    <span v-html="(new Date()).toLocaleString('de-DE', {month: 'long'})"></span>&nbsp;Leads
                </th>
                <th class="py-0.5 px-2 text-left">Status</th>
                <th class="py-0.5 px-2 text-left border-r border-gray-300"></th>
            </tr>
            <tr v-for="vendor in sector.vendors" class="bg-gray-200 even:bg-gray-100 hover:bg-gray-300 border-1" :class="{'opacity-40': vendor.isRejected}">
                <td class="px-1 p-0.5">
                    <div class="flex items-start justify-start">
                        <span v-html="vendor.name ?? vendor.software_name ?? vendor.vendor_name" :title="vendor.vendor_name"></span>
                        
                        <div class="relative group">
                            <font-awesome-icon class="ml-1 mt-0.5 text-md" v-if="!isEmpty(vendor.info_leads)" :icon="'info-circle'"></font-awesome-icon>

                            <div v-if="!isEmpty(vendor.info_leads)" class="absolute top-full bg-white p-2 z-50 border border-gray-300 shadow-2xl text-left hidden group-hover:block">
                                <p v-html="vendor.info_leads" class="min-w-[400px]"></p>
                            </div>
                        </div>
                    </div>

                    <template v-if="shouldTicketBeUpdated(vendor.id)">
                        <input type="hidden" :name="`tickets[${vendor.id}][id]`" :value="getTicket(vendor.id).id">
                        <input type="hidden" :name="`tickets[${vendor.id}][vendor_id]`" :value="vendor.id">
                        <!-- TODO: rename to status -->
                        <input type="hidden" :name="`tickets[${vendor.id}][state]`" :value="getTicketStatus(vendor.id)">
                        <input type="hidden" :name="`tickets[${vendor.id}][allocated_at]`" :value="getTicket(vendor.id).allocated_at">
                        <input type="hidden" :name="`tickets[${vendor.id}][accepted_at]`" :value="getTicket(vendor.id).accepted_at">
                        <input type="hidden" :name="`tickets[${vendor.id}][denied_at]`" :value="getTicket(vendor.id).denied_at">
                        <input type="hidden" :name="`tickets[${vendor.id}][has_changed]`" :value="getTicket(vendor.id).has_changed">
                    </template>
                </td>
                <td class="text-center">
                    <span v-if="this.isUndefined(vendor.users_exists) || vendor.users_exists" v-html="vendor.tickets_count"></span>
                </td>
                <td class="px-1">
                    <div class="whitespace-nowrap w-32">
                        <div class="w-3 h-3 rounded-full inline-block align-middle mr-1" :class="getStatusColor(vendor.id)"></div>
                        <!-- if status message is "kostenfreie leads" make text color orange -->
                        <span v-html="getStatusMessage(vendor.id)" :class="{'text-orange-500': getStatusMessage(vendor.id) === 'kostenfreie Leads'}"></span>
                    </div>
                </td>
                <td class="px-1 pb-0.5 pt-1.5 border-r border-gray-300">
                    <div class="h-10 flex justify-end items-center gap-2 w-[170px]">
                        <button
                            class="lmt-btn-xs lmt-btn-success py-5 -mt-0.5 mr-auto" 
                            :disabled="!lead.data_transfer_accepted || vendor.isRejected" 
                            v-if="isTicketStatus(vendor.id, TicketStatuses.Considered) || isTicketStatus(vendor.id, TicketStatuses.Denied)" 
                            @click.prevent="setTicketStatus(vendor, TicketStatuses.Pending)">

                            Zuweisen
                        </button>

                        <button
                            class="lmt-btn-xs lmt-btn-success py-5 -mt-0.5 mr-auto" 
                            :disabled="!lead.data_transfer_accepted || vendor.isRejected" 
                            v-if="isTicketStatus(vendor.id, TicketStatuses.Accepted)"
                            @click.prevent="setTicketStatus(vendor, TicketStatuses.Denied)">
                            
                            Zurücknehmen
                        </button>
                      <!--
                      <lead-send-email class="self-start mt-5" email-template="{{ $emailTemplate }}" :lead-id="{{ $lead->id }}"></lead-send-email>
                      -->
                      <SendEmail
                        class="lmt-btn-xs lmt-btn-success py-5 -mt-0.5 mr-auto"
                        :email-template="this.epostBlaupause"
                        :lead-id="lead.id"
                        :lead="lead"
                        :modal-title="'E-Mail an ' + vendor.name + ' senden'"
                        :mail-to="vendor.email"
                        :for-vendor="true"
                        v-if="getStatusMessage(vendor.id) === 'kostenfreie Leads' || getStatusMessage(vendor.id) === 'Kein Vertrag'"
                      ></SendEmail>
                        <a v-if="!vendor.isRejected" :class="disabledClasses(!vendor.calendar_url)" :href="vendor.calendar_url" target="blank">
                            <font-awesome-icon class="text-2xl" :icon="'calendar'"></font-awesome-icon>
                        </a>

                        <a v-if="!vendor.isRejected" :class="disabledClasses(!vendor.onboarding_url)" :href="vendor.onboarding_url" target="blank">
                            <font-awesome-icon class="text-2xl" :icon="'calendar-plus'"></font-awesome-icon>
                        </a>

                        <button v-if="!vendor.isRejected" @click.prevent="rejectVendor(vendor)"><font-awesome-icon class="text-2xl" :icon="'ban'"></font-awesome-icon></button>
                        <button v-if="vendor.isRejected" @click.prevent="restoreVendor(vendor)"><font-awesome-icon class="text-2xl" :icon="'arrow-rotate-back'"></font-awesome-icon></button>
                    </div>
                </td>
            </tr>
            <tr class="bg-sky-900/20">
                <th class="py-0.5 px-2 text-left">Anbieter</th>
                <th class="py-0.5 px-2 text-left">
                    <span v-html="(new Date()).toLocaleString('de-DE', {month: 'long'})"></span>&nbsp;Leads
                </th>
                <th class="py-0.5 px-2 text-left">Status</th>
                <th class="py-0.5 px-2 text-left"></th>
            </tr>
        </table>
    </template>
</template>

<script lang="ts">

import { defineComponent } from "vue";

import { Store, useStore } from "vuex";
import { key, RootStoreInterface } from "../../Store";
import { LeadMap } from "../../Store/Lead";

import { Lead, Sector, Ticket, Vendor } from "../../typings/types"
import { SendEmail } from "@/components/Leads/SendEmail";
import SendEmail from "./SendEmail.vue";


enum TicketStatuses {
    NoContract = 'no_contract',
    ContractExpired = 'contract_expired',
    Considered = 'considered',
    Pending = 'pending',
    Accepted = 'accepted',
    Returned = 'returned',
    Denied = 'denied',
    CustomerClosing = 'customer_closing',
    FreeLead = 'free_lead'
}

const TicketColors = {
    [TicketStatuses.NoContract]: 'bg-gray-300 text-orange',
    [TicketStatuses.ContractExpired]: 'bg-gray-300',
    [TicketStatuses.Considered]: 'bg-blue-400',
    [TicketStatuses.Pending]: 'lmt-bg-main-blue',
    [TicketStatuses.Accepted]: 'bg-purple-800',
    [TicketStatuses.Returned]: 'bg-red-700',
    [TicketStatuses.Denied]: 'bg-red-400',
    [TicketStatuses.CustomerClosing]: 'bg-green-500',
    // freeleads should have an orange dot
    [TicketStatuses.FreeLead]: 'bg-orange-500'
}

const TicketMessages = {
    [TicketStatuses.NoContract]: 'Kein Vertrag',
    [TicketStatuses.ContractExpired]: 'Vertrag abgelaufen',
    [TicketStatuses.Considered]: 'In Betracht',
    [TicketStatuses.Pending]: 'Ausstehend',
    [TicketStatuses.Accepted]: 'Zugewiesen',
    [TicketStatuses.Returned]: 'Abgelehnt',
    [TicketStatuses.Denied]: 'Zurückgenommen',
    [TicketStatuses.CustomerClosing]: 'Abgeschlossen',
    [TicketStatuses.FreeLead]: 'kostenfreie Leads'
}

type LeadAllocationData = {
    TicketStatuses: Object,
    lead: Lead,
    expandedSector: number|null,
    store: Store<RootStoreInterface>
}


export default defineComponent({
  components: {SendEmail},
    props: {
        initialLead: {
            type: Object as () => Lead,
            required: true
        },
    },

    data() {
        return {
            TicketStatuses,
            lead: this.initialLead,
            expandedSector: null,
            store: useStore(key),
            epostBlaupause: '<p>Lieber Anbieter,</p><br><p>wir sind von Systemhaus.com und möchten Ihnen dieses Lead schenken. Wir überlassen Ihnen die Daten freiwillig um unsere Bereitschaft zur Zusammenarbeit zu zeigen. Wir würden uns über eine Antwort sehr freuen, wenn wir Ihnen auch unseren Vertriebsservice anbieten dürften, der direkt mit einem Lead spricht und einen Folgetermin mit Ihrem Support macht oder direkt den Kunden die Software zeigen kann. Wir sind offen für provisionierte Vorschläge, aber testen Sie in Ruhe erstmal. Wir danken Ihnen sehr.</p><br><p>Herzliche Grüße,</p><p>Ihr Team von Systemhaus.com</p>',
        }
    },

    mounted() {
    console.log("mounted", this.lead)
        this.store.commit(LeadMap.mutations.setIsDataTransferAccepted, this.lead.data_transfer_accepted)
	
	if (this.lead.tickets === undefined) {
        this.lead.tickets = []
      }
      console.log("mounted", this.lead)	

        // console log leaddata
        const lead_data = JSON.parse(JSON.stringify(this.lead))
        console.log(lead_data)
        // extract user data and sectors[0].wizard_questions
        const user_data = lead_data.user
        const wizard_questions = lead_data.sectors[0].wizard_questions
        const payload = {
            user_data,
            wizard_questions
        }
        console.log(payload)
    },

    computed: {
        sectors: function(): Sector {
            let sectors = this.store.getters[LeadMap.getters.getSectors]

            if (this.isEmpty(sectors)) {
                return sectors
            }

            this.expandedSector = sectors.at(-1).id

            return sectors
        },
        console: () => console,
        window: () => window,
    },

    methods: {
        toggleSector(sectorId) {
            this.expandedSector = this.expandedSector === sectorId ? null : sectorId
        },

        shouldTicketBeUpdated(vendorId: number): boolean {
            const ticketStatus = this.getTicketStatus(vendorId)

            return ticketStatus === TicketStatuses.Pending || ticketStatus === TicketStatuses.Denied || ticketStatus === TicketStatuses.Accepted
        },

        setTicketStatus(vendor: Vendor, ticketStatus: TicketStatuses) {
            let ticket = this.getTicket(vendor.id)
          console.log("setTicketStatus", vendor, ticketStatus, ticket)

            let allocatedAt = !this.isNull(ticket?.allocated_at) ? ticket.allocated_at : null
            let acceptedAt = !this.isNull(ticket?.accepted_at) ? ticket.accepted_at : null
            let deniedAt = !this.isNull(ticket?.denied_at) ? ticket.denied_at : null

            if (ticketStatus === TicketStatuses.Denied) {
                deniedAt = (new Date()).toISOString()
            }

            if (ticketStatus === TicketStatuses.Pending) {
                allocatedAt = (new Date()).toISOString()
            }

            if (ticketStatus === TicketStatuses.Pending && vendor.current_contract?.auto_lead_delivery) {
                ticketStatus = TicketStatuses.Accepted
                acceptedAt = allocatedAt
            }

            if (ticketStatus === TicketStatuses.Denied && this.isNull(ticket.id)) {
                this.lead.tickets = this.lead.tickets.filter(ticket => ticket.vendor_id !== vendor.id)

                return
            }

            if (this.isUndefined(ticket)) {
                this.lead.tickets.push({
                    lead_id: this.lead.id,
                    vendor_id: vendor.id,
                    status: ticketStatus,
                    allocated_at: allocatedAt,
                    accepted_at: acceptedAt,
                    denied_at: deniedAt,
                    has_changed: true
                });

                return
            }

            ticket.status = ticketStatus
            ticket.allocated_at = allocatedAt
            ticket.accepted_at = acceptedAt
            ticket.denied_at = deniedAt
            ticket.has_changed = true
        },

        getTicket(vendorId: number): Ticket|null {
            return this.lead.tickets.find((ticket: Ticket) => ticket.vendor_id === vendorId)
        },

        getTicketStatus(vendorId: number): TicketStatuses {
            let vendor;

            this.sectors.some((sector: Sector) => {
                vendor = sector.vendors.find((vendor: Vendor) => vendor.id === vendorId)

                if (!this.isUndefined(vendor)) {
                    return true;
                }
            })

            if (this.isUndefined(vendor)) {
                return
            }

            if (!this.isUndefined(vendor.users_exists) && !vendor.users_exists) {
                return
            }

            // if vendor.freelead == 1, return TicketStatuses.FreeLead
            if (vendor.freelead == 1) {
                return TicketStatuses.FreeLead
            }

            if (!vendor.contracts_exists) {
                return TicketStatuses.NoContract
            }

            if (vendor.contracts_exists && this.isNull(vendor.current_contract)) {
                return TicketStatuses.ContractExpired
            }

            let ticket = this.getTicket(vendorId)

            return ticket?.status ?? TicketStatuses.Considered
        },

        getStatusMessage(vendorId: number): string {
            const status = this.getTicketStatus(vendorId)

            return TicketMessages[status]
        },

        getStatusColor(vendorId: number): string {
            const status = this.getTicketStatus(vendorId)

            return TicketColors[status]
        },

        isTicketStatus(vendorId: number, ticketStatus: TicketStatuses): boolean {
            return this.getTicketStatus(vendorId) === ticketStatus
        },

        rejectVendor(vendor: Vendor) {
            vendor.isRejected = true
        },

        restoreVendor(vendor: Vendor) {
            vendor.isRejected = false
        },

        updateIsDataTransferAcceptedState() {
            this.store.commit(LeadMap.mutations.setIsDataTransferAccepted, this.lead.data_transfer_accepted)
        },

        disabledClasses(condition: boolean): string {
            return condition ? 'opacity-50 cursor-not-allowed' : ''
        },

        askMail(vendor: Vendor) {
          // ask user for destination email and use vendor.flexible_field as default
          const email = prompt('Ist die E-Mail Adresse korrekt?', vendor.flexible_field)
          // if email is not null, set vendor.flexible_field to email

          if (!email) {
            return
          }


          if (email) {
            vendor.flexible_field = email
          }

          // if cancel, do nothing


          // prepare data for log
          const lead_data = JSON.parse(JSON.stringify(this.lead))
          /*
          * needed:
          * lead {
          *   id, full_name, company_name, email, phone, street, zip, city, message
          * },
          * wizard_questions {
          *   id, question, answer where answer is inside lead_answer.answer
          * }
          * */

          // extract user data and sectors[0].wizard_questions
          const user_data = lead_data.user
          const wizard_questions = lead_data.sectors[0].wizard_questions
          const payload = {
            user_data,
            wizard_questions,
            msg: lead_data.message,
            send_to: email
          }

          // construct "view(email.newVendor, payload)"
          // send this to PUT /leads/:leadid/sanae
          // also add csrf token
          // log response
          const url = `/newven/snae`
          fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify(payload)
          })
            // response will be a html page
            .then(response => response.text())
            .then(data => {
              // set status to TicketStatuses.Accepted
              this.setTicketStatus(vendor, TicketStatuses.Accepted)
              console.log("set status to TicketStatuses.Accepted", vendor)
            })
            .catch(error => console.log(error))

        }
    }
})

</script>
