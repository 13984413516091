
import { InjectionKey } from 'vue'
import { createStore, ModuleTree, Store } from 'vuex'
import { Lead } from './Lead'

export interface RootStoreInterface {}

export const key: InjectionKey<Store<RootStoreInterface>> = Symbol()

const modules: ModuleTree<RootStoreInterface> = {
    Lead,
}

export const store = createStore<RootStoreInterface>({
    modules: modules
})
