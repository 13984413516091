<template>
    <div class="cursor-pointer flex select-none" @click="isExpanded = !isExpanded">
        <div class="font-semibold self-center flex">
            <font-awesome-icon class="mr-4 mt-0.5 text-4xl transform transition-transform" :class="{'rotate-90': isExpanded}" :icon="['fa', 'caret-right']"/>
        </div>

        <h2>Basis Eigenschaften (<span v-html="initialFeatures.length"></span>)</h2>
    </div>
    
    <abstract-manager 
        :class="{'hidden': !isExpanded}"
        modelName="base_feature"
        displayName="Basis Eigenschaft"
        :initialModels="initialFeatures"
        :errorIndexes="errorIndexes"
        :isFixedRelation="true">

        <template v-slot:default="slotProps: Feature">
             <div class="lmt-form-group mb-3 w-1/3 pr-3">
                <label :for="`base_features[${slotProps.index}][name]`">Name</label>
                <input :id="`base_features[${slotProps.index}][name]`" :name="`base_features[${slotProps.index}][name]`" type="text" readonly v-model="slotProps.base_feature.name">
            </div>

            <div class="lmt-form-group mb-3 flex-grow">
                <label :for="`base_features[${slotProps.index}][short_description]`">Kurzbeschreibung</label>
                <input :id="`base_features[${slotProps.index}][short_description]`" :name="`base_features[${slotProps.index}][short_description]`" type="text" readonly v-model="slotProps.base_feature.short_description">
            </div>
        </template>

        <template v-slot:body="slotProps">
            <div>
                <div class="flex justify-between">
                    <div class="lmt-form-group mb-3 flex-grow pr-2">
                        <label :for="`base_features[${slotProps.index}][pivot][value]`">Wert</label>
                        <input :id="`base_features[${slotProps.index}][pivot][value]`" :name="`base_features[${slotProps.index}][pivot][value]`" type="text" v-model="slotProps.base_feature.pivot.value">
                    </div>
                    <div v-if="parentType === 'vendor'" class="lmt-form-group mb-3 flex-grow pl-2">
                        <label :for="`base_features[${slotProps.index}][pivot][comment]`">Bemerkung</label>
                        <input :id="`base_features[${slotProps.index}][pivot][comment]`" :name="`base_features[${slotProps.index}][pivot][comment]`" type="text" v-model="slotProps.base_feature.pivot.comment">
                    </div>
                </div>

                <div v-if="parentType === 'vendor'" class="flex justify-between pb-3">
                    <div>
                        <label class="font-bold block">Versteckt</label>
                        <div class="lmt-form-group-radio inline-block mr-5">
                            <label :for="`base_features[${slotProps.index}][pivot][is_hidden][yes]`">
                                <input :id="`base_features[${slotProps.index}][pivot][is_hidden][yes]`" :name="`base_features[${slotProps.index}][pivot][is_hidden]`" type="radio" value="1" v-model="slotProps.base_feature.pivot.is_hidden">
                                <span>Ja</span>
                            </label>
                        </div>
                        <div class="lmt-form-group-radio inline-block">
                            <label :for="`base_features[${slotProps.index}][pivot][is_hidden][no]`">
                                <input :id="`base_features[${slotProps.index}][pivot][is_hidden][no]`" :name="`base_features[${slotProps.index}][pivot][is_hidden]`" type="radio" value="0" selected="true" v-model="slotProps.base_feature.pivot.is_hidden">
                                <span>Nein</span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <label class="font-bold block text-center">Vorhanden</label>
                        <div class="lmt-form-group-radio inline-block mr-5">
                            <label :for="`base_features[${slotProps.index}][pivot][is_available][yes]`">
                                <input :id="`base_features[${slotProps.index}][pivot][is_available][yes]`" :name="`base_features[${slotProps.index}][pivot][is_available]`" type="radio" value="1" v-model="slotProps.base_feature.pivot.is_available">
                                <span>Ja</span>
                            </label>
                        </div>
                        <div class="lmt-form-group-radio inline-block mr-2">
                            <label :for="`base_features[${slotProps.index}][pivot][is_available][no]`">
                                <input :id="`base_features[${slotProps.index}][pivot][is_available][no]`" :name="`base_features[${slotProps.index}][pivot][is_available]`" type="radio" value="0" v-model="slotProps.base_feature.pivot.is_available">
                                <span>Nein</span>
                            </label>
                        </div>
                    </div>
                    <div>
                        <label class="font-bold block text-right">Gegen Aufpreis</label>
                        <div class="lmt-form-group-radio inline-block mr-5">
                            <label :for="`base_features[${slotProps.index}][pivot][is_extra_charge_required][yes]`">
                                <input :id="`base_features[${slotProps.index}][pivot][is_extra_charge_required][yes]`" :name="`base_features[${slotProps.index}][pivot][is_extra_charge_required]`" type="radio" value="1" v-model="slotProps.base_feature.pivot.is_extra_charge_required">
                                <span>Ja</span>
                            </label>
                        </div>
                        <div class="lmt-form-group-radio inline-block">
                            <label :for="`base_features[${slotProps.index}][pivot][is_extra_charge_required][no]`">
                                <input :id="`base_features[${slotProps.index}][pivot][is_extra_charge_required][no]`" :name="`base_features[${slotProps.index}][pivot][is_extra_charge_required]`" type="radio" value="0" selected="true" v-model="slotProps.base_feature.pivot.is_extra_charge_required">
                                <span>Nein</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </abstract-manager>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractManager from "./AbstractManager.vue";
import { AbstractManagedModel } from "../../typings/types";

type Feature = AbstractManagedModel


export default defineComponent({
    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        initialFeatures: {
            type: Object as () => Array<Feature>,
            default: new Array<Feature>(),
        },
        isForVendor: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: {},
        },
        parentType: {
            type: String,
            options: ['vendor', 'it-service-provider']
        }
    },

    data() {
        return {
            isExpanded: false
        }
    },

    computed: {
        errorIndexes: function() {
            const errorKeys = Object.keys(this.errors)
            const regex = /\.(\d+)\./g
            let indexes: Array<number> = []

            errorKeys.forEach((errorKey: string) => {
                const matches = regex.exec(errorKey)

                if (matches !== null && typeof matches[1] !== 'undefined') {
                    indexes.push(Number(matches[1]));
                }
            })

            return indexes
        }
    },

    methods: {
        hasError(key: string, index: number) {
            const errorKeys = Object.keys(this.errors)

            if (errorKeys.length < 1) {
                return false
            }

            return -1 < (errorKeys.findIndex(errorKey => {
                return errorKey.indexOf(`.${index}.${key}`) > -1
            }))
        },

        getError(key: string, index: number) {
            const errorKeys = Object.keys(this.errors)

            const errorIndex = errorKeys.findIndex(errorKey => {
                return errorKey.indexOf(`.${index}.${key}`) > -1
            })

            return Object.values(this.errors)[errorIndex][0]
        }
    }
})

</script>
