/**
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap.ts'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import { createApp } from 'vue'

import Editor from '@tinymce/tinymce-vue';
import {Quill, QuillEditor} from "@vueup/vue-quill";
// quill
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import LeadAllocations from './components/Leads/Allocations.vue'
import LeadProtocol from './components/Leads/Protocol.vue'
import LeadQuestions from './components/Leads/Questions.vue'
import LeadSendEmail from './components/Leads/SendEmail.vue'
import LeadSendEmailToNewVendor from './components/Leads/SendEmailToNewVendor.vue'
import LeadQualitySelector from './components/Leads/QualitySelector.vue'
import AbstractModal from './components/AbstractModal.vue'
import AbstractManager from './components/RelationShipManagers/AbstractManager.vue'
import AbstractSelectorManager from './components/RelationShipManagers/AbstractSelectorManager.vue'
import ManageQuestions from './components/RelationShipManagers/ManageQuestions.vue'
import ManageFeatures from './components/RelationShipManagers/ManageFeatures.vue'
import ManageItServiceProviders from './components/RelationShipManagers/ManageItServiceProviders.vue'
import ManageBaseFeatures from './components/RelationShipManagers/ManageBaseFeatures.vue'
import ManageSectorFeatures from './components/RelationShipManagers/ManageSectorFeatures.vue'
import ManageTags from './components/RelationShipManagers/ManageTags.vue'
import ManageSectors from './components/RelationShipManagers/ManageSectors.vue'
import ManageCities from './components/RelationShipManagers/ManageCities.vue'
import ManageContract from './components/RelationShipManagers/ManageContract.vue'
import ManageCustomers from './components/RelationShipManagers/ManageCustomers.vue'
import ManageComparisonSites from './components/RelationShipManagers/ManageComparisonSites.vue'
import ManageVendors from './components/RelationShipManagers/ManageVendors.vue'
import BillingButton from './components/Billing/BillingButton.vue'
import ToggleBillingStatus from './components/Billing/ToggleBillingStatus.vue'
import BillingRow from './components/Billing/Row.vue'
import SelectInput from './components/Utilities/SelectInput.vue'
import UsersTable from './components/Users/Table.vue'
import VendorsTable from './components/Vendors/Table.vue'

import { store, key } from './Store'

import mixins from './mixins'

import { createI18n } from 'vue-i18n'
import de from '../lang/de'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const i18n = createI18n({
    locale: 'de',
    messages: {de}
})

createApp({
    components: {
        FontAwesomeIcon,
        Editor,
        QuillEditor,
        LeadAllocations,
        LeadProtocol,
        LeadQuestions,
        LeadSendEmail,
        LeadSendEmailToNewVendor,
        LeadQualitySelector,
        AbstractModal,
        AbstractManager,
        AbstractSelectorManager,
        ManageQuestions,
        ManageFeatures,
        ManageItServiceProviders,
        ManageBaseFeatures,
        ManageSectorFeatures,
        ManageTags,
        ManageSectors,
        ManageCities,
        ManageContract,
        ManageCustomers,
        ManageVendors,
        BillingButton,
        ToggleBillingStatus,
        BillingRow,
        SelectInput,
        UsersTable,
        VendorsTable,
        ManageComparisonSites
    },
})
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('select-input', SelectInput)
    .component('lead-quality-selector', LeadQualitySelector)
    .component("quill-editor", QuillEditor)
    .use(store, key)
    .use(i18n)
    .mixin(mixins)
    .mount('#app')