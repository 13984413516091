<template>
    <div :class="$attrs.class">
        <h2 v-html="title"></h2>
        <abstract-manager
            modelName="itServiceProvider"
            displayName="IT-Dienstleister"
            :initialModels="selectedItServiceProviders"
            :selectableModels="selectableItServiceProviders"
            :usesDefaultAddModelButton="false"
            :deleteButtonClasses="'mt-1'"
            :newModelId="newItServiceProviderId"
            :inputListClasses="'grid grid-cols-3 gap-4'"
            ref="abstractManagerRef">

            <template v-slot:default="slotProps: ItServiceProvider">
                <div class="flex flex-wrap pt-1">
                    <h3 :class="{'w-1/2 lg:w-1/3 2xl:w-1/4': !isNull(oldFeatures)}" v-html="slotProps.itServiceProvider.name"></h3>
                    <input :name="`itServiceProvider[${slotProps.itServiceProvider.id}][name]`" type="hidden" :value="slotProps.itServiceProvider.name">
                </div>
            </template>

            <template v-slot:selector>
                <select class="mt-2 w-full" v-model="newItServiceProviderId" @change="triggerSelection">
                    <option :value="null" v-html="'Wähle einen IT-Dienstleister aus'" hidden></option>
                    <option v-for="itServiceProvider in selectableItServiceProviders" :value="itServiceProvider.id" v-html="itServiceProvider.name"></option>
                </select>
            </template>

        </abstract-manager>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractManager from "./AbstractManager.vue";
import { ItServiceProvider, Feature } from "../../typings/types";
import mixins from "../../mixins";

type ManageItServiceProviderData = {
    newItServiceProviderId: number|null,
    selectedItServiceProviders: Array<ItServiceProvider>
}

export default defineComponent({
    mixins: [mixins],

    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        itServiceProviders: {
            type: Array as () => Array<ItServiceProvider>,
            required: true,
        },
        initialSelectedItServiceProviders: {
            type: Array as () => Array<ItServiceProvider>,
            default: new Array<ItServiceProvider>()
        },
        oldFeatures: {
            type: Array as () => Array<Feature>,
            default: null,
        },
        title: {
            type: String,
            default: 'Verwandte Branchen',
            required: false,
        }
    },
    
    data(): ManageItServiceProviderData {
        return {
            newItServiceProviderId: null,
            selectedItServiceProviders: this.initialSelectedItServiceProviders,
        }
    },

    computed: {
        selectableItServiceProviders: function() {
            return this.itServiceProviders.filter(itServiceProvider => {
                const hasBeenSelected = this.isUndefined(this.selectedItServiceProviders.find(selectedItServiceProvider => {
                    return selectedItServiceProvider.id === itServiceProvider.id && !selectedItServiceProvider.is_marked_for_destruction
                }))

                return hasBeenSelected;
            });
        }
    },

    beforeMount() {
        if (!this.isNull(this.oldFeatures) && this.oldFeatures.length > 0) {
            this.selectedItServiceProviders = this.initialSelectedItServiceProviders.map(itServiceProvider => {
                itServiceProvider.features = this.oldFeatures.filter(feature => {
                    return feature.pivot!.itServiceProvider_id === itServiceProvider.id
                })

                return itServiceProvider
            })
        }
    },

    methods: {
        triggerSelection() {
            (this.$refs.abstractManagerRef as typeof AbstractManager).addModel(this.newItServiceProviderId);

            this.newItServiceProviderId = null;
        }
    }
})

</script>
