<template>
    <tbody>
        <tr class="align-top hover:bg-gray-100 border-gray-300 border-t-2 peer">
            <td class="p-3" :rowspan="rowSpan">
                <span class="sticky lmt-top-210 text-base font-bold" v-html="vendor.name"></span>
            </td>

            <td class="text-center text-base font-semibold p-3 cursor-pointer select-none border-x border-gray-300  hover:bg-gray-200 hover:underline" colspan="12" @click="toggleCollapse">
                <font-awesome-icon class="mr-2 -mb-0.75 text-base transform transition-transform" :class="{'rotate-90': isExpanded}" :icon="['fa', 'caret-right']"/>

                <span v-if="!isExpanded">Ausklappen</span>
                <span v-if="isExpanded">Einklappen</span>
            </td>

            <td class="p-3 text-right" :rowspan="rowSpan" v-html="vendor.total_price"></td>
            <td class="p-3 text-right" :rowspan="rowSpan" v-html="vendor.unbilled_tickets_count"></td>
            <td class="py-1 px-3" :rowspan="rowSpan">
                <billing-button
                    v-if="!vendor.contract.is_flat_rate"
                    :vendor-id="vendor.id"
                    :month="month"
                    :unbilled-tickets-count="vendor.unbilled_tickets_count"
                    v-on:vendor-update="vendorUpdate">

                </billing-button>

                <button class="mt-2 px-3 py-2 bg-blue-600 hover:bg-blue-500 text-white" @click="downloadCSV(vendor, month)">Download PDF</button>

                <span v-if="!vendor.contract.is_flat_rate && isNull(vendor.contract.sevdesk_id)">sevDesk-ID fehlt</span>
                <div class="text-center mt-2" v-if="vendor.contract.is_flat_rate">Leadflat</div>
            </td>
        </tr>

        <template v-if="isExpanded">
            <tr v-for="(ticket, index) in vendor.tickets" class="align-top hover:bg-gray-200 border-gray-300 border-t">
                <td class="p-3 border-l border-gray-300"><a class="text-blue-700 underline" :href="`/leads/${ticket.lead.id}/edit`" target="blank">
                    #{{ ticket.lead.id }}<br>{{ ticket.lead?.user?.full_name }}
                </a></td>
                <td class="p-3" v-html="dateFormat(ticket.allocated_at)"></td>
                <td class="p-3" v-html="dateFormat(ticket.denied_at) ?? '-'"></td>
                <td class="p-3" v-html="dateFormat(ticket.accepted_at) ?? 'Nicht angenommen'"></td>
                <td class="p-3">
                    <span class="p-1"
                        :class="{ 'bg-red-200': ticket.time_till_viewed > 48, 'bg-yellow-200': ticket.time_till_viewed > 24 && ticket.time_till_viewed <= 48 }"
                        v-html="dateFormat(ticket.viewed_at) ?? 'Nicht gesehen'">
                    </span>
                </td>
                <td class="p-3" :class="{ 'line-through': !isNull(ticket.return_rejected_at) }" v-html="dateFormat(ticket.returned_at) ?? '-'"></td>
                <td class="p-3" v-html="dateFormat(ticket.billed_at) ?? '-'"></td>
                <td class="p-3" v-html="ticket.vendor_message ?? '-'"></td>
                <td class="p-3" v-html="ticket.billing_status"></td>
                <td class="p-3 relative">
                    <div :id="`lead-quality-trigger-${vendor.id}`" class="cursor-pointer" @click="editLeadQuality(ticket.id)">
                        <span
                            class="px-0.5 mr-3"
                            :class="{'bg-red-600 text-white': isNull(ticket.lead?.lead_quality)}"
                            v-html="capitalizeFirst(leadQualityFriendlyName(ticket.lead?.lead_quality))">
                        </span>

                        <font-awesome-icon class="text-lg" :icon="'edit'"></font-awesome-icon>
                    </div>

                    <div class="absolute top-1 left-0 w-56 bg-gray-50 border broder-gray-500 p-2" v-if="isLeadQualitySelectorVisible(ticket.id)">
                        <lead-quality-selector :lead="ticket.lead" v-on:change="updateLeadQuality(ticket, $event)"></lead-quality-selector>
                    </div>

                    <div class="mt-3">
                        <div v-if="ticket.follow_up_protocol_entries_exists"><font-awesome-icon class="text-green-600 text-base" :icon="'check'"></font-awesome-icon>Folgetermin</div>
                        <div v-if="ticket.on_boarding_protocol_entries_exists"><font-awesome-icon class="text-green-600 text-base" :icon="'check'"></font-awesome-icon> Onboarding</div>
                    </div>
                </td>
                <td class="p-3 text-right" v-html="ticket.price"></td>
                <td class="p-3 text-right border-r border-gray-300">
                    <toggle-billing-status :initial-ticket='ticket' :vendor-id='vendor.id' :month='month' v-on:vendor-update="vendorUpdate"></toggle-billing-status>
                </td>
            </tr>
        </template>
    </tbody>
</template>

<script lang="ts">

import axios from "axios";

import mixins from "../../mixins";

import ToggleBillingStatus from "./ToggleBillingStatus.vue";
import BillingButton from "./BillingButton.vue";

import { defineComponent } from "vue";
import { Lead, Ticket, Vendor } from "../../typings/types.js";

export default defineComponent({
    components: {
        'toggle-billing-status': ToggleBillingStatus,
        'billing-button': BillingButton,
    },

    mixins: [mixins],
    
    props: {
        initialVendor: Object,
        month: String,
    },

    data() {
        return {
            vendor: this.initialVendor,
            isExpanded: false,
            showLeadQualityForTicketId: null,
        }
    },

    mounted() {
        window.addEventListener('click', event => {
            if((event.target as HTMLElement).closest(`#lead-quality-trigger-${this.vendor.id}`) !== null) {
                return
            }

            this.closeLeadQuality();
        });
    },

    computed: {
        rowSpan: function () {
            return this.isExpanded ? this.vendor.tickets.length + 1 : 1
        },
    },

    methods: {
        toggleCollapse() {
            this.isExpanded = !this.isExpanded
        },

        vendorUpdate(updatedVendor) {
            this.vendor = updatedVendor
        },

        isLeadQualitySelectorVisible(ticketId: number) {
            return this.showLeadQualityForTicketId === ticketId
        },

        editLeadQuality(ticketId: number) {
            this.showLeadQualityForTicketId = ticketId
        },

        closeLeadQuality() {
            this.showLeadQualityForTicketId = null
        },

        leadQualityFriendlyName(leadQuality: string) {
            switch (leadQuality) {
                case 'premium_plus':
                    return 'Prime Plus'
                case 'prime':
                    return 'Prime'
                case 'secondary':
                  case 'second':
                    return 'Second'
                case 'third':
                    return 'Third'
              default:
                return 'fehlt';
            }
        },

        updateLeadQuality(ticket: Ticket, event) {            
            const url = `/leads/${ticket.lead.id}/updateLeadQuality`;

            axios.put(url, {
                    lead_quality: event.target.value,
                    ticket_id: ticket.id,
                    vendor_id: ticket.vendor_id,
                    month: this.month,
                })
                .then(response => {
                    ticket.lead.lead_quality = response.data.ticket.lead.lead_quality
                    ticket.price = response.data.ticket.price
                    this.vendor.total_price = response.data.vendor.total_price
                }).finally(() => {
                    this.closeLeadQuality()
                })
        },

        downloadCSV(vendor: Vendor) {
            const url = `/billing/pdf/${vendor.id}?month=${this.month}`;

            axios.get(url, {
                responseType: 'blob'
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', `Leadübersicht-${this.month}-${this.vendor.software_name}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        }
    }
})

</script>