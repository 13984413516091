<template>
    <div :class="$attrs.class">
        <h2>Ansprechpartner</h2>
        <abstract-manager
            modelName="customer"
            displayName="Ansprechpartner"
            :initialModels="selectedCustomers"
            :selectableModels="selectableCustomers"
            :usesDefaultAddModelButton="false"
            :newModelId="newCustomerId"
            :deleteButtonClasses="'mt-1'"
            ref="abstractManagerRef">

            <template v-slot:default="slotProps: Customer">
                <div class="flex flex-grow py-2">
                    <input :name="`customers[${slotProps.customer.id}][full_name]`" type="hidden" :value="slotProps.customer.full_name">
                    <input :name="`customers[${slotProps.customer.id}][email]`" type="hidden" :value="slotProps.customer.email">

                    <span v-html="`${slotProps.customer.full_name} [${slotProps.customer.email}]`"></span>
                </div>
            </template>

            <template v-slot:selector>
                <select class="mt-2" v-model="newCustomerId" @change="triggerSelection">
                    <option :value="null" v-html="'Wähle einen Vertragspartner aus'" hidden></option>
                    <option v-for="customer in selectableCustomers" :value="customer.id" v-html="`${customer.full_name} [${customer.email}]`"></option>
                </select>
            </template>
        </abstract-manager>
    </div>
</template>

<script lang="ts">

import { defineComponent } from "vue"
import AbstractManager from "./AbstractManager.vue"
import { Customer } from "../../typings/types"
import mixins from "../../mixins"

export default defineComponent({
    mixins: [mixins],

    components: {
        'abstract-manager': AbstractManager,
    },

    props: {
        customers: {
            type: Array as () => Array<Customer>,
            default: new Array<Customer>({id: null, name: ''}),
            required: true
        },
        initialSelectedCustomers: {
            type: Array as () => Array<Customer>,
            default: new Array<Customer>(),
            required: true
        }
    },
    
    data() {
        return {
            newCustomerId: null,
            selectedCustomers: this.initialSelectedCustomers
        }
    },

    computed: {
        selectableCustomers: function() {
            return this.customers.filter(customer => {
                const hasBeenSelected = this.isUndefined(this.selectedCustomers.find(selectedCustomer => {
                    return selectedCustomer.id === customer.id && !selectedCustomer.is_marked_for_destruction
                }))

                return hasBeenSelected
            });
        }
    },

    methods: {
        triggerSelection() {
            (this.$refs.abstractManagerRef as typeof AbstractManager).addModel(this.newCustomerId)

            this.newCustomerId = null
        }
    }
})

</script>
