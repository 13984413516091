<template>  
    <div 
        class="flex items-center border border-gray-500 px-2 h-[30px] bg-gray-50 shadow-md hover:bg-white hover:shadow-lg cursor-pointer" 
        :class="$attrs.class"
        @click="openEmailModal">

        <font-awesome-icon class="text-xl" :icon="'envelope'"></font-awesome-icon>
    </div>

    <abstract-modal :is-open="isModalOpen" title="Email an Anbieter senden" v-on:closeModal="closeEmailModal">
        <template v-slot:modalBody>
            <blockquote v-if="hasError" class="bg-red-100 text-red-700 px-3 py-2 my-5 border border-red-200">Es ist ein Fehler beim Versenden der E-Mail aufgetreten. Senden Sie bitten einen Screenshot, Leadnummer und eine Beschreibung der letzten Schritte an den Administrator.</blockquote>
            <blockquote v-if="hasSuccess" class="bg-green-100 text-green-700 px-3 py-2 my-5 border border-green-200">E-Mail wurde erfolgreich versendet</blockquote>



          <quill-editor
              v-model="message"
              :content="message"
              :content-type="'html'"
              class="min-h-[300px]"
              @text-change="updateMessage"
              theme="snow"></quill-editor>

        <!-- send and cancel buttons -->
        <div class="flex justify-end mt-5">
            <button class="btn btn-primary" @click="sendEmail" :disabled="isLoading">Senden</button>
            <button class="btn btn-secondary ml-2" @click="closeEmailModal" :disabled="isLoading">Abbrechen</button>
        </div>

        </template>
    </abstract-modal>
</template>

<script lang="ts">

import { defineComponent } from "vue";
import AbstractModal from "@/components/AbstractModal.vue";
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";
import {QuillEditor} from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css'

type SendEmailData = {
    isModalOpen: boolean,
    isLoading: boolean,
    hasError: boolean,
    hasSuccess: boolean,
    message: string,
}

export default defineComponent({
    props: {
        emailTemplate: String,
        leadId: Number,
        lead: Object,
    },

    components: {
        'abstract-modal': AbstractModal,
        'editor': QuillEditor
    },

    data(): SendEmailData {
        return {
            isModalOpen: false,
            isLoading: false,
            hasError: false,
            hasSuccess: false,
            message: this.emailTemplate,
        }
    },

    methods: {
        openEmailModal() {
            this.isModalOpen = true
        },

        closeEmailModal() {
            this.isLoading = false
            this.hasError = false
            this.hasSuccess = false

            this.isModalOpen = false
        },

        sendEmail() {
          // ask user for destination email and use vendor.flexible_field as default
          const email = prompt('Ist die E-Mail Adresse korrekt?', vendor.flexible_field)
          // if email is not null, set vendor.flexible_field to email

          if (!email) {
            return
          }


          if (email) {
            vendor.flexible_field = email
          }

          // if cancel, do nothing


          // prepare data for log
          const lead_data = JSON.parse(JSON.stringify(this.lead))
          /*
          * needed:
          * lead {
          *   id, full_name, company_name, email, phone, street, zip, city, message
          * },
          * wizard_questions {
          *   id, question, answer where answer is inside lead_answer.answer
          * }
          * */

          // extract user data and sectors[0].wizard_questions
          const user_data = lead_data.user
          const wizard_questions = lead_data.sectors[0].wizard_questions
          const payload = {
            user_data,
            wizard_questions,
            msg: lead_data.message,
            send_to: email
          }

          // construct "view(email.newVendor, payload)"
          // send this to PUT /leads/:leadid/sanae
          // also add csrf token
          // log response
          const url = `/newven/snae`
          fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify(payload)
          })
              // response will be a html page
              .then(response => response.text())
              .then(data => {
                // set status to TicketStatuses.Accepted
              })
              .catch(error => console.log(error))
        },

        updateMessage(event) {
            // Update the message data on input
            this.message = this.$refs.editor.innerHTML;
        },
        applyFormat(command, value = null) {
            // Execute formatting commands
            document.execCommand(command, false, value);
            this.updateMessage(); // Update the message after formatting
        },
        updateEditorContent() {
            // Update the contenteditable div when the textarea changes
            this.$refs.editor.innerHTML = this.message;
        },

        updateMessage(event) {
            // Update the message data on input
            this.message = this.$refs.editor.innerHTML;
            console.log(this.message)
        },

        logmessage() {
            console.log(this.message)
        }
    }
})

</script>

<style scoped>

.editor-container {
    max-height: 500px;
    overflow-y: auto;
}

/* style for editor content */

#editor p {
    margin: 0;
}

#editor a {
    color: #3182ce;
    text-decoration: underline;
}

/* Add any custom styles for your component here */
.formatters {
    margin-bottom: 10px;
}

.editor-container {
    display: flex;
}

</style>